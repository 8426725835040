<template>
  <div class="qr-code" :style="{ '--inputFocusColor': inputFocusBottomColor }">
    <div class="title">微信二维码登录</div>
    <div class="code-img" v-show="isBindWeChatToAccountView == false">
      <!-- 联想二维码 -->
      <div id="wxLogin-lenovo" v-if="isLenovoWeChat">
        <div class="wxLogin-lenovo-img">
          <img v-cdnimg :src="wxLoginLenovoImgSrc" v-show="isValid(wxLoginLenovoImgSrc) && wxLoginLenovoImgSrc != ''" />
        </div>
        <div class="info">
          <p>使用微信扫一扫登录</p>
          <p>微信扫码后请先关注“联想智慧教育”公众号，再使用微信重新扫描二维码，即可正常登录</p>
        </div>
      </div>
      <!-- 标准平台二维码 -->
      <div id="wxLogin" v-else></div>
    </div>
    <!-- 微信首次登录绑定账户 -->
    <div class="bind-account-container" v-if="isBindWeChatToAccountView == true">
      <div class="bind-account-wechat">
        <input
          v-model="account"
          type="text"
          class="clear-input general-input"
          spellcheck="false"
          placeholder="账号"
          @click="inputSelected = '账号'"
          @blur="inputBlur"
          :class="{ 'input-focus': inputSelected == '账号' }"
        />
        <input
          v-model="password"
          type="password"
          class="clear-input general-input"
          spellcheck="false"
          placeholder="密码"
          @click="inputSelected = '密码'"
          @blur="inputBlur"
          :class="{ 'input-focus': inputSelected == '密码', 'login-pwd': true }"
        />
      </div>
      <div class="tip">首次使用，请您将当前微信与账号绑定</div>
      <!-- 绑定按钮 -->
      <div
        class="bind-btn"
        @click="bindWeChatAndAccount"
        :style="{ 'background-color': submitBtnBackgroundColor }"
      >
        <div class="login-text">绑&nbsp;定</div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import {
  GET_WEIXIN_ACCOUNT,
  // GET_ACCOUNT_ROLE,
  GET_ACCOUNT_SEED,
  LOGIN_ACCOUNT_JSON,
  LOGIN_ACCOUNT_AIDU,
  WEIXIN_BIND_ACCOUNT,
  GET_LENOVO_TICKET,
  GET_SCANNINGS_STATUS,
  WX_BINDING_USER
} from "@/http/api.js";
export default {
  components: {},
  props: ["isSecondLogin", "config", "previewConfig", "Mode"],
  data() {
    return {
      isBindWeChatToAccountView: false,
      account: "", // 输入的用户名
      password: "", // 输入的密码
      inputSelected: "", // 选择的输入框，用来定位光标在那个输入框
      UnionID: null,
      Nickname: null,
      // wechatRoleList: [], // 微信扫码登录多用户数组
      // wechatUnionId: null,
      ErrorNum: 0, // 输错密码次数

      isLenovoWeChat: false,
      wxLoginLenovoImgSrc: "",

      platformNum: '', // 记录平台编号
    };
  },
  computed: {
    inputFocusBottomColor() {
      // 点击输入框时输入框底部的颜色
      if (this.Mode == "preview") {
        // 预览模式
        return this.previewConfig.colorConfig.inputBoxSelect;
      } else {
        return this.config.colorConfig.inputBoxSelect;
      }
    },
    submitBtnBackgroundColor() {
      // 提交按钮的背景色
      if (this.Mode == "preview") {
        // 预览模式
        return this.previewConfig.colorConfig.loginButton;
      } else {
        return this.config.colorConfig.loginButton;
      }
    },
    platform() {
      return this.$store.state.platform;
    },
  },
  watch: {
    platform: {
      handler(newVal) {
        this.platformNum = newVal.PlatformSystemNumber;
        if (this.isValid(this.platformNum)) {
          if (this.platformNum == "000040" || this.platformNum == "000041") {
            this.isLenovoWeChat = true
            GET_LENOVO_TICKET().then(result=>{
              let data = result.data
              let ticket = data.Data;
              if(this.isValid(ticket) && ticket != ""){
                this.wxLoginLenovoImgSrc = "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + ticket
                this.openLenovoWechatInterVal(ticket)
              }else{
                this.$message.error(data.Msg)
              }
            })
          }else{
            this.isLenovoWeChat = false
            this.$nextTick(() => {
              window.addEventListener("message", this.receiveMessage, false);
              this.getQRCode(this.platformNum); // 获取二维码
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    openLenovoWechatInterVal(ticket){
      this.lenovoWeChatInterVal = setInterval(() => {
        const param = {
          ticket: ticket
        }
        GET_SCANNINGS_STATUS(this.$qs.stringify(param)).then(result=>{
          let data = result.data
          let openId = data.Data
          if(openId && openId != 0 && openId != -1){
            clearInterval(this.lenovoWeChatInterVal)
            const param = {
              unionId: openId
            }
            WX_BINDING_USER(this.$qs.stringify(param)).then(response=>{
              let result = response.data.result
              if(this.isValidArray(result)){
                let data = result[0]
                let isBinding = data.isBinding;
                if (!isBinding) { // 未绑定
                  let unionid = data.unionId;
                  let nickname = data.nickName;
                  nickname = nickname.replace(/\'/g, "&#39;");
                  if (unionid) {
                    this.UnionID = unionid;
                    this.Nickname = nickname;
                    this.redirectToBinding(unionid, nickname);
                  } else {
                    this.$alert("应用未注册，请联系管理员", "提示", {
                      confirmButtonText: "确定",
                      callback: () => {
                        this.PaneClass = "login-switch";
                        this.LoginPane = "pc";
                      },
                    });
                  }
                }else{
                  let userList = data.accounts;
                  let unionId = data.unionId;
                  if (this.isValidArray(userList)) {
                    // 已绑定用户数量大于1出现用户选择，否则直接跳转
                    if (userList.length > 1) {
                      // this.wechatRoleList = userList;
                      // this.wechatUnionId = unionId;
                      this.onGetSession(userList, unionId, () => {
                        userList = userList.filter(i => i.session != '')
                        this.$bus.$emit("QRCodeBindingRole", userList); // 显示选账号和角色的页面
                      })
                    } else {
                      let param = {
                        unionId: unionId,
                        loginName: userList[0].loginname,
                      };
                      GET_WEIXIN_ACCOUNT(this.$qs.stringify(param)).then((res) => {
                        // console.log("微信登录成功之后的回调",res);
                        let result = res.data.result;
                        if (this.isValidArray(result)) {
                          this.$bus.$emit("QRCodeShowChooseRole", result); // 显示选账号和角色的页面
                        }
                      });
                    }
                  }
                }
              }
            })
          }
        })
      }, 2000);
    },
    // selectDiffRole() {
    //   // 登录之后选择不同的角色
    //   let param = {
    //     session: this.getSession(),
    //     hostName: location.hostname,
    //     page: 0,
    //   };
    //   GET_ACCOUNT_ROLE(this.$qs.stringify(param)).then((res) => {
    //     this.$bus.$emit("QRCodeShowChooseRole", res);
    //   });
    // },
    getQRCode(platformNum) {
      // 获取二维码
      let env = this.getEnv(); // 获取当前的环境变量
      let QRCodeStyle =
        "data:text/css;base64,LmltcG93ZXJCb3ggewogICAgbWFyZ2luLXRvcDogMDsKfQouaW1wb3dlckJveCAucXJjb2RlIHsKICAgIHdpZHRoOiAxOThweDsKICAgIG1hcmdpbi10b3A6IDA7CiAgICBib3JkZXI6IDFweCBzb2xpZCAjRTVFNkVCOwogICAgYm9yZGVyLXJhZGl1czogOHB4Owp9Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5pbmZvIAp7CiAgICBmb250LWZhbWlseTogTWljcm9zb2Z0IFlhSGVpLFNlZ29lIFVJLFRhaG9tYSxBcmlhbCxWZXJkYW5hLHNhbnMtc2VyaWYgIWltcG9ydGFudDsKICAgIHdpZHRoOiAxMDAlOwogICAgY29sb3I6ICM0RTU5Njk7CiAgICBtYXJnaW4tdG9wOiAyOHB4Owp9Ci5zdGF0dXNfaWNvbiB7CiAgICBkaXNwbGF5OiBub25lCn0KLmltcG93ZXJCb3ggLnN0YXR1cyB7CiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0=";
      if (platformNum == "000040" || platformNum == "000041") {
        QRCodeStyle =
          "data:text/css;base64,LmltcG93ZXJCb3ggewogIG1hcmdpbi10b3A6IDA7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgd2lkdGg6IDE5OHB4OwogIG1hcmdpbi10b3A6IDA7CiAgYm9yZGVyOiAxcHggc29saWQgI0U1RTZFQjsKICBib3JkZXItcmFkaXVzOiA4cHg7Cn0KLmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5pbmZvIAp7CiAgZm9udC1mYW1pbHk6IE1pY3Jvc29mdCBZYUhlaSxTZWdvZSBVSSxUYWhvbWEsQXJpYWwsVmVyZGFuYSxzYW5zLXNlcmlmICFpbXBvcnRhbnQ7CiAgd2lkdGg6IDEwMCU7CiAgY29sb3I6ICM0RTU5Njk7CiAgbWFyZ2luLXRvcDogMjhweDsKfQouc3RhdHVzX2ljb24gewogIGRpc3BsYXk6IG5vbmUKfQouaW1wb3dlckJveCAuc3RhdHVzIHsKICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciBwOmxhc3QtY2hpbGR7CiAgZGlzcGxheTogbm9uZTsKfQ==";
      }
      let appid = "wxadb8015957ec2a78"; //ForClass[production]
      if (env == "test") {
        platformNum = "000001"; // 此处需要设置为ForClass的platformNum，否则 二维码 加载报错（redirect_uri 参数错误）
        appid = "wxa200e2fe634e622c"; //ForClass[test]
      }
      if (platformNum == "000011") {
        appid = "wxd396cba0adcc7ff6"; //271BAY
      }
      if (platformNum == "000012" || platformNum == "000032") {
        platformNum = "000012";
        appid = "wx16de51f47ccd27b8"; //Sunclass
      }
      if (platformNum == "000040") {
        platformNum = "000001";
      }
      let protocol = location.protocol;
      let redirectUri = protocol + "//test-ws.forclass.net";
      if (env == "production") {
        // 若为河南项目需要替换接口地址
        if(location.host.indexOf('hasmartedu.com') > -1){
          redirectUri = protocol + "//webservice-jzjx.hasmartedu.com";
        } else {
          redirectUri = protocol + "//webservice.forclass.net";
        }
      }
      redirectUri += "/WeChat/WXLoginOAuthFCCallback2";
      redirectUri = encodeURI(redirectUri);
      
      new WxLogin({
        self_redirect: true,
        id: "wxLogin",
        appid: appid,
        scope: "snsapi_login,snsapi_userinfo",
        redirect_uri: redirectUri,
        state: "binding_" + platformNum,
        style: "black",
        href: QRCodeStyle,
      });
      // 样式文件如下，用Base64加密一下放到"data:text/css;base64,"的逗号后面就行
      // .impowerBox {
      //     margin-top: 0;
      // }
      // .impowerBox .qrcode {
      //     width: 198px;
      //     margin-top: 0;
      //     border: 1px solid #E5E6EB;
      //     border-radius: 8px;
      // }
      // .impowerBox .title {
      //     display: none;
      // }
      // .impowerBox .info
      // {
      //     font-family: Microsoft YaHei,Segoe UI,Tahoma,Arial,Verdana,sans-serif !important;
      //     width: 100%;
      //     color: #4E5969;
      //     margin-top: 28px;
      // }
      // .status_icon {
      //     display: none
      // }
      // .impowerBox .status {
      //     text-align: center;
      // }
    },
    inputBlur() {
      // 输入框失去焦点取消下划线高亮
      this.inputSelected = "";
    },
    /**
     * isNeedFetchUserInfo
     * 联想扫码绑定账号接口返回值中没有 用户信息（很少）
     * 需要调用 Vue.prototype.validateSession 获取用户信息
     *
     */
    handleLogin(res, isNeedFetchUserInfo) {
      // 处理登录之后接口返回数据
      console.log("绑定完登录接口返回的内容", res);
      let result = res.data.result;
      if (!this.isValidArray(result)) {
        // 没有登录成功
        var returnText = res.data.ReturnText;
        var loginMsg = "";
        switch (returnText) {
          case "ErrCode:0 DB throwed Exception.":
            loginMsg = "网络繁忙，请稍后再试";
            break;
          case "ErrCode:0 Account is not exist.":
            loginMsg = "此用户名不存在";
            break;
          case "ErrCode:0 Password is not correct":
            this.ErrorNum++;
            let msg =
              "用户名或密码不正确，您还可以尝试" + (3 - this.ErrorNum) + "次";
            if (this.ErrorNum >= 3) {
              msg = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试";
            }
            loginMsg = msg;
            break;
          case "ErrCode:0 Account is forbidden":
            loginMsg = "此账户已被禁用，请联系管理员";
            break;
          case "ErrCode:0 Account expired":
            loginMsg = "此账户过期，请联系管理员";
            break;
          case "Invalid request":
            loginMsg = "请勿频繁操作";
          case "Too much failures":
            loginMsg = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试";
            break;
          case "Already binded":
            loginMsg =
              "您所登录的账户已经被其他微信号绑定，请联系管理员确认账户绑定情况。";
            break;
          case "WXLogin student not allowed":
            loginMsg = "暂不支持使用学生账户绑定微信号。";
            break;
          case "Teachers have no classes":
            loginMsg = "该账号没有任教班级，请先任教，再扫码绑定！";
            break;
          case "ErrCode:-1067 该账号已被绑定！":
            loginMsg = "该账号已被绑定！";
            break;
          case "ErrCode:-1070 该账号没有任教班级，请先任教，再扫码！":
            loginMsg = "该账号没有任教班级，请先任教，再扫码！";
            break;
          //case -104:
          //    loginMsg = "会话无效，请联系管理员";
          //    break;
          default:
            loginMsg = returnText;
            break;
        }
        this.$message.error(loginMsg);
      } else {
        // 绑定成功
        let acnt = result[0];
        if (isNeedFetchUserInfo) {
          this.validateSession(() => {
            // 跳转选账号和选角色的页面
            this.isBindWeChatToAccountView = false;
            this.$bus.$emit("QRCodeBindingRole", result); // 显示选账号和角色的页面
          }, acnt.session);
        } else {
          let acnt = result[0];
          let account = {
            userid: acnt.userid,
            session: acnt.session,
            loginname: this.account,
            realname: acnt.realname,
            account: acnt.showname, //showname是数据库返回的账户名（优先真实姓名，账户名次之）
            role: acnt.role,
            coin: acnt.coin,
            level: acnt.level,
            avatar: acnt.avatar,
          };
          this.saveAccount(account);
          this.validateSession(() => {
            // 跳转选账号和选角色的页面
            this.isBindWeChatToAccountView = false;
            this.$bus.$emit("QRCodeBindingRole", result); // 显示选账号和角色的页面
          });
        }
      }
    },
    ExecuteLogin(loginName, pwd) {
      // 调接口绑定账号(旧，会修改密码的)
      // let identity = sessionStorage["stamp"] || new Date().getTime();
      let identity = new Date().getTime();
      sessionStorage["stamp"] = identity;
      GET_ACCOUNT_SEED(this.$qs.stringify({ stamp: identity })).then((res) => {
        // 获取加密种子
        let data = res.data;
        let key = data.key;
        let iv = data.iv;
        let encryptedAccount = this.encrypt(loginName, key, iv);
        let encryptedPwd = this.encrypt(pwd, key, iv);
        let dataObj = {
          account: encryptedAccount,
          password: encryptedPwd,
          unionid: this.UnionID || null,
          nickname: this.Nickname || null,
          xStr: JSON.stringify({ stamp: identity }),
        };
        let client_id = this.getRequestParamValue("client_id");
        let loginData = { account: loginName, password: pwd };
        //let loginApi = 'http://localhost:55771/AccountService.asmx/LoginAccountJson'
        //wpsUA3f6c9Mcde  爱读-金山
        //mp15s49Cdc4FB219e    爱读-粤教云
        if (this.isValid(client_id) && client_id != "") {
          // 这块没理解
          loginData.client_id = client_id;
          LOGIN_ACCOUNT_AIDU(this.$qs.stringify(loginData)).then((res) => {
            this.handleLogin(res);
          });
        } else {
          let platform = this.$store.state.platform;
          let platformNum = platform.PlatformSystemNumber;
          console.log(platformNum);
          if (platformNum == "000040" || platformNum == "000041") {
            // 联想平台 接口不换密码
            let param = {
              session: "",
              openId: "",
              loginName: loginName,
              pwd: pwd,
              unionId: this.UnionID,
              nickName: this.Nickname,
            };
            WEIXIN_BIND_ACCOUNT(this.$qs.stringify(param)).then((res) => {
              let result = res.data.result;
              // 接口返回 prop 为 Name
              if (this.isValidArray(result)) {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
                console.log("绑定成功");
                res.data.result[0].session = res.data.result[0].Name;
                console.log(res.data.result[0]);
                this.handleLogin(res, true);
              } else {
                this.handleLogin(res, true);
              }
            });
          } else {
            loginData = dataObj;
            LOGIN_ACCOUNT_JSON(this.$qs.stringify(loginData)).then((res) => {
              this.$message({
                message: "绑定成功",
                type: "success",
              });
              this.handleLogin(res);
            });
          }
        }
      });
    },
    bindWeChatAndAccount() {
      // 绑定微信和账号
      if (this.account == "") {
        this.$message.error("请填写账号");
        return false;
      }
      if (this.password == "") {
        this.$message.error("请填写密码");
        return false;
      }
      this.ExecuteLogin(this.account, this.password);
    },
    // 如果没有绑定过账号则展示绑定页面
    redirectToBinding() {
      this.isBindWeChatToAccountView = true;
    },
    /**
     * 标准微信扫码登录 message 回调
     * 微信扫码成功后的回调
     */
    receiveMessage(e) { 
      var data = e.data;
      console.log("扫码之后的返回值", e);
      if (data) {
        let param = data.param;
        if (data.cmd == "binding") {
          let unionid = param.unionid;
          let nickname = param.nickname;
          nickname = nickname.replace(/\'/g, "&#39;");
          if (unionid) {
            this.UnionID = unionid;
            this.Nickname = nickname;
            this.redirectToBinding(unionid, nickname);
          } else {
            this.$alert("应用未注册，请联系管理员", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                this.PaneClass = "login-switch";
                this.LoginPane = "pc";
              },
            });
          }
        }
        if (data.cmd == "pass") {
          let userList = data.param.user.user;
          let unionId = data.param.user.unionId;
          userList = JSON.parse(userList);
          if (this.isValidArray(userList)) {
            // 已绑定用户数量大于1出现用户选择，否则直接跳转
            if (userList.length > 1) {
              // this.wechatRoleList = userList;
              // this.wechatUnionId = unionId;
              this.onGetSession(userList, unionId, () => {
                userList = userList.filter(i => i.session != '')
                this.$bus.$emit("QRCodeBindingRole", userList); // 显示选账号和角色的页面
              })
            } else {
              let param = {
                unionId: unionId,
                loginName: userList[0].loginname
              };
              GET_WEIXIN_ACCOUNT(this.$qs.stringify(param)).then(res => {
                if(res.data.ReturnCode == 1) {
                  let result = res.data.result;
                  if (this.isValidArray(result)) {
                    this.$bus.$emit("QRCodeShowChooseRole", result); // 显示选账号和角色的页面
                  }
                } else {
                  let reg = /[\u4e00-\u9fa5]+/g;
                  let tips = res.data.ReturnText.match(reg);
                  this.$message.error(tips[0]);
                  this.getQRCode(this.platformNum); // 获取二维码
                }
              });
            }
          }
        }
      }
    },
    async onGetSession(list, unionId, next) {
      let arr = [];
      list.forEach(i => {
        let params = { unionId, loginName: i.loginname }
        arr.push(GET_WEIXIN_ACCOUNT(this.$qs.stringify(params)));
      })
      let result = await Promise.all(arr);
      list.forEach(i => {
        result.forEach(t => {
          let res = t.data.result;
          if(this.isValidArray(res)) {
            if(i.userid == res[0].Idx) {
              i.session = res[0].Name;
            }  
          } 
        })
      })
      next();
    }
  },
  created() {
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage, false);
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox(); // 清除原生input样式
.input-focus {
  border-bottom-color: var(--inputFocusColor) !important;
}
.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #1d2129;
  }
  .code-img {
    margin-top: 32px;
    #wxLogin-lenovo{
      display: flex;
      flex-direction: column;
      align-items: center;
      .wxLogin-lenovo-img{
        width: 200px;
        height: 200px;
        border: 1px solid #E5E6EB;
        img{
          width: 200px;
          height: 200px;
        }
      }
      .info {
        // width: 100%;
        width: 70%;
        color: #4E5969;
        margin-top: 28px;
        text-align: center;
        font-size: 13px;
      }
    }
  }
  .bind-account-container {
    .bind-account-wechat {
      display: flex;
      flex-direction: column;
      .login-pwd {
        margin-bottom: 0 !important;
      }
    }
  }
  .general-input {
    // 短信登录的输入框
    box-sizing: border-box;
    height: 38px;
    border-bottom: 2px solid #f2f3f5;
    margin-bottom: 32px;
  }
  .general-input:nth-child(1) {
    margin-top: 24px;
  }
  .tip {
    // 提示语
    font-size: 13px;
    color: #ff3b30;
    font-weight: 400;
    margin-top: 16px;
  }
}
.bind-btn {
  // 登录
  margin-top: 32px;
  width: 392px;
  height: 40px;
  background-color: #ff6a00;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  .login-text {
    // width: 38px;
    height: 22px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>