<template>
    <div class="index-page">
        <!-- <div v-loading="loading" class="loading"></div> -->
    </div>
</template>

<script>
import {
    NECIBOOK_GET_USERCENTER
} from '@/http/api.js';
export default {
    name: 'Index',
    data() {
        return {
            loading: true, // loading
            appid: '60073',
        }
    },
    async created() {
        // 跳转到河南教育的登录页面
        const state = this.$route.query.state || 'win'; // win-windows端【默认】 | android-安卓端
        const appid = this.$route.query.appid || this.appid; // appid可以通过链接参数但也有默认值
        // let redirectUri = this.getCurrentEnv() == 'test' ? 'https://test-account.forclass.net/Hasmartedu/Auth' : 'https://account-jzjx.hasmartedu.com/Hasmartedu/Auth';
        // let redirectUri = 'https://account-jzjx.hasmartedu.com/Hasmartedu/Auth';
        const redirectUri = `${location.origin}/Hasmartedu/Auth`;

        const authUrl = `https://jz.hasmartedu.com/login/open/oauth2/authorize`;
        localStorage.setItem('Hasmartedu', state);
        window.location.href = `${authUrl}?appid=${appid}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=RESPONSE_TYPE&state=${state}&showbg=1`;
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;

    .loading {
        width: 100%;
        height: 100%;
    }
}
</style>