<template>
    <div class="signin" :class="{ bg: isLenovoPlatform }" :style="{ background: 'url(' + backgroundImageURL + ')' }">
        <div class="container">
            <!-- 右侧登录区域 -->
            <div class="login">
                <div class="login-content">
                    <!-- 右上角切换登录方式  二维码或者PC的图标 -->
                    <div class="toggle-view" @click="isScanCode = !isScanCode" v-show="showTopRightCorner && !isClient">
                        <!-- 扫码登录图标 -->
                        <div v-show="isScanCode == true">
                            <svg-icon iconName="PCLogin" :config="config" :previewConfig="previewConfig"
                                :Mode="Mode"></svg-icon>
                        </div>

                        <!-- 正常登录图标 -->
                        <div v-if="isScanCode == false">
                            <svg-icon iconName="QRCode" :config="config" :previewConfig="previewConfig"
                                :Mode="Mode"></svg-icon>
                        </div>

                    </div>
                    <!-- 顶部logo -->
                    <div class="logo">
                        <div class="logo-img" v-show="showLogoImg">
                            <img v-cdnimg ref="platformLogo" :src="logoUrl" alt="">
                        </div>
                        <div class="logo-text" v-show="showLogoText" :style="{ 'color': logoTextColor }">
                            {{ logoText }}
                        </div>
                    </div>

                    <!-- PC登录组件，包括普通登录和二次登录 -->
                    <!-- 普通登录 -->
                    <login v-show="isScanCode == false" :config="config" :type="type"
                        :isLenovoPlatform="isLenovoPlatform" @showQRcode="showQRcode"
                        @hideTopRightCornerIcon="hideTopRightCornerIcon"
                        @showTopRightCornerIcon="showTopRightCornerIcon" @onIsClient="onIsClient"
                        :previewConfig="previewConfig" :Mode="Mode">
                    </login>
                    <!-- 扫码登录 -->
                    <scan-code :previewConfig="previewConfig" :Mode="Mode" :config="config" v-if="isScanCode == true">
                    </scan-code>
                </div>
                <!-- 底部备号信息 -->
                <div class="footer" v-show="isShowFooterICP">
                    <pre>{{ ICPText }}</pre>
                </div>
            </div>
        </div>
        <!-- <div class="keyboard-mask">
        <simple-keyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"></simple-keyboard>
    </div> -->
        <Footer></Footer>
    </div>
</template>

<script>
import Login from '@/components/signin/Login.vue'
import ScanCode from '@/components/signin/ScanQRCode.vue'
import SvgIcon from '@/components/signin/Widgets/SvgIcon.vue'
import { GET_LOGIN_CONFIG, GET_PLATFORM_ID } from '@/http/api.js'
import SimpleKeyboard from '@/components/signin/Widgets/SimpleKeyboard.vue'
import Footer from '@/components/signin/Widgets/Footer.vue';
import cookie from 'js-cookie'
export default {
    components: { Login, ScanCode, SvgIcon, SimpleKeyboard, Footer },
    data() {
        return {
            isScanCode: false, // 是否是扫码登录
            showTopRightCorner: true, // 右上角PC和扫码登录图标
            type: 'platform', // 登录类型：平台登录-'platform', 管理模块登录-'manage', 个人中心登录-'account'
            imageURL: require("../../../public/Account/images/signin/backgroundImg.png"),
            config: {
                basicConfig: {},
                colorConfig: {
                    loginModeSelect: {
                    },
                    qRcodeOrAccountIcon: {
                    }
                },
                loginModeConfigs: [{}]
            },              // 标准时的配置
            previewConfig: {
                basicConfig: {},
                colorConfig: {
                    loginModeSelect: {
                    },
                    qRcodeOrAccountIcon: {
                    }
                },
                loginModeConfigs: [{}]
            },      // 预览时的配置
            formworkConfig: {       // 模板配置
                "code": "",
                "basicConfig": {
                    "logo": "",
                    "logoEnableStatus": false,
                    "loginPageText": "",
                    "loginPageTextEnableStatus": false,
                    "backGroundImage": "",
                    "forgetPasswordEnableStatus": false,
                    "registerNowEnableStatus": false,
                    "rememberPasswordEnableStatus": false,
                    "icp": "",
                    "icpEnableStatus": false
                },
                "colorConfig": {
                    "inputBoxSelect": "",
                    "rememberPassword": "",
                    "loginButton": "",
                    "otherText": "",
                    "loginModeSelect": {
                        "startColor": "",
                        "endColor": ""
                    },
                    "qRcodeOrAccountIcon": {
                        "icon": "",
                        "backgroundColor": ""
                    }
                },
                "loginModeConfigs": [
                    {
                        "modeCode": "AccountLogin",
                        "modeName": "账号登录",
                        "platformEnableStatus": false,
                        "adminmoduleEnableStatus": false,
                        "personalCenterEnableStatus": false,
                        "order": 1
                    },
                    {
                        "modeCode": "CommandLogin",
                        "modeName": "口令登录",
                        "platformEnableStatus": false,
                        "adminmoduleEnableStatus": false,
                        "personalCenterEnableStatus": false,
                        "order": 2
                    },
                    {
                        "modeCode": "ShortMessageLogin",
                        "modeName": "短信登录",
                        "platformEnableStatus": false,
                        "adminmoduleEnableStatus": false,
                        "personalCenterEnableStatus": false,
                        "order": 3
                    },
                    {
                        "modeCode": "EmailLogin",
                        "modeName": "邮箱登录",
                        "platformEnableStatus": false,
                        "adminmoduleEnableStatus": false,
                        "personalCenterEnableStatus": false,
                        "order": 4
                    },
                    {
                        "modeCode": "ScanCodeLogin",
                        "modeName": "扫码登录",
                        "platformEnableStatus": false,
                        "adminmoduleEnableStatus": false,
                        "personalCenterEnableStatus": false,
                        "order": 5
                    }
                ]
            },
            Mode: '',    // 是否处于预览模式，正常是空''，预览时变成preview
            isLenovoPlatform: false,    // 是否是联想登录

            isClient: false,
        }
    },
    watch: {},
    computed: {
        backgroundImageURL() {         // 背景图片
            if (this.Mode == 'preview') {   // 预览模式
                if (this.isValid(this.previewConfig.basicConfig.backGroundImage)) {
                    return this.previewConfig.basicConfig.backGroundImage
                } else {
                    return this.imageURL
                }
            } else {                            // 正式模式
                if (this.isValid(this.config.basicConfig) && this.isValid(this.config.basicConfig.backGroundImage)) {
                    return this.config.basicConfig.backGroundImage
                } else {
                    return this.imageURL
                }
            }
        },
        showLogoImg() {               // 是否显示登录logo
            if (this.Mode == 'preview') {   // 预览模式
                if (this.previewConfig.basicConfig.logoEnableStatus == true) {
                    return true
                } else {
                    return false
                }
            } else {                            // 正式模式
                if (this.config.basicConfig.logoEnableStatus == true) {
                    return true
                } else {
                    return false
                }
            }
        },
        showLogoText() {              // 是否显示欢迎文字
            if (this.Mode == 'preview') {   // 预览模式
                if (this.previewConfig.basicConfig.loginPageTextEnableStatus == true) {
                    return true
                } else {
                    return false
                }
            } else {                            // 正式模式
                if (this.config.basicConfig.loginPageTextEnableStatus == true) {
                    return true
                } else {
                    return false
                }
            }
        },
        logoUrl() {                   // 登录页面登录区域顶部logo地址
            if (this.Mode == 'preview') {   // 预览模式
                // （显示logo还是显示文字，后台管理有配置，文字和logo只能显示一个）
                if (this.previewConfig.basicConfig.logoEnableStatus == true) {   // logoEnableStatus代表logo是否显示
                    return this.previewConfig.basicConfig.logo
                }
            } else {   // 正常模式
                if (this.config.basicConfig.logoEnableStatus == true) {   // logoEnableStatus代表logo是否显示
                    return this.config.basicConfig.logo
                }
            }
        },
        logoText() {                  // 登录页面登录区域顶部文字
            if (this.Mode == 'preview') { // 预览模式
                if (this.previewConfig.basicConfig.loginPageTextEnableStatus == true) {   // 显示文字
                    return this.previewConfig.basicConfig.loginPageText
                }
            } else {   // 正常模式
                if (this.config.basicConfig.loginPageTextEnableStatus == true) {
                    return this.config.basicConfig.loginPageText
                }
            }
        },
        logoTextColor() {             // 登录页面logo如果是文字时的颜色
            if (this.Mode == 'preview') { // 预览模式
                if (this.previewConfig.basicConfig.loginPageTextEnableStatus == true) {   // 显示文字
                    return this.previewConfig.colorConfig.otherText
                }
            } else {   // 正常模式
                if (this.config.basicConfig.loginPageTextEnableStatus == true) {
                    return this.config.colorConfig.otherText
                }
            }
        },
        isShowFooterICP() {           // 是否显示底部备案信息
            if (this.Mode == 'preview') { // 预览模式
                if (this.previewConfig.basicConfig.icpEnableStatus) {
                    return true
                } else {
                    return false
                }
            } else {   // 正常模式
                if (this.config.basicConfig.icpEnableStatus) {
                    return true
                } else {
                    return false
                }
            }
        },
        ICPText() {                   // 备案信息文字
            if (this.Mode == 'preview') { // 预览模式
                return this.previewConfig.basicConfig.icp
            } else {   // 正常模式
                return this.config.basicConfig.icp
            }
        }
    },
    methods: {
        /**
         * @params
         * 判断备授课客户端进入联想客户端是否有账号关联
         */
        onIsClient(val) {
            this.isClient = val;
        },
        showQRcode() {   // 默认显示二维码登录
            this.isScanCode = true
        },
        hideTopRightCornerIcon() {   // 隐藏右上角的电脑和二维码图标
            this.showTopRightCorner = false
        },
        showTopRightCornerIcon() {  // 显示右上角的电脑和二维码图标
            this.showTopRightCorner = true
        },
        receiveMessage(event) {   // 预览时获取预览配置
            // 向后台配置页回消息'yes'，证明收到了
            event.source.postMessage('yes', event.origin)
            let url = [
                'http://test-console.forclass.net',
                'https://test-console.forclass.net',
                'http://console.forclass.net',
                'https://console.forclass.net',
            ]
            if (!url.includes(event.origin)) {
                return;    // 如果不是上述四个地址发过来的信息，则不处理
            } else {
                let configInfo = event.data
                // 如果信息里面有这个四个属性说明数据是正确的
                if (configInfo.code && configInfo.basicConfig && configInfo.colorConfig && configInfo.loginModeConfigs) {
                    this.Mode = 'preview'
                    this.previewConfig = configInfo  // 从paas项目后台管理-统一登录配置预览跳转过来时，获取的配置信息
                } else {
                    this.Mode = ''   // 普通模式
                }
            }
            // 移除监听事件
            window.removeEventListener("message", this.receiveMessage, false);
        },

        // onChange(input) {
        // // input 是当前点击按钮的值
        // },
        // onKeyPress(button) {
        //     if (button == "{enter}") {
        //         // 如果按确认键的相应操作
        //     }
        //     if (button == "{bksp}") {
        //     // 删除键的相应操作
        //     }
        // },
    },
    beforeCreate() { },
    created() {
        document.title = '登录'
        var query = this.$route.query
        // 第三方登录
        var code = query.code
        if (this.isValid(code) && code != "") {
            let param = {
                codeList: [code]
            }
            GET_LOGIN_CONFIG(param).then((res) => {
                if (res.data.status.success == true) {
                    if (this.isValidArray(res.data.data)) {
                        let currentConfig = res.data.data[0]  // 当前的配置
                        this.Mode = ''
                        this.config = currentConfig
                    }
                } else {
                    this.$message.error('未获取到登录配置')
                }
            })
            return
        }

        let hostNameParam = {
            hostName: location.hostname
            // hostName: 'test-account.k12smartedu.com'
            // hostName: 'test-account.forclass.net'
        }

        if (hostNameParam.hostName == "localhost") {
            hostNameParam.hostName = process.env.VUE_APP_HOSTNAME;
        }

        let redirecturi = this.getRequestParamValue('redirecturi');
        GET_PLATFORM_ID(hostNameParam).then((res) => {
            // GET_PLATFORM_ID(this.$qs.stringify(hostNameParam)).then((res)=>{
            if (res.data.ReturnCode == 1) {
                let result = res.data.result
                if (this.isValidArray(result)) {
                    if (result[0].PlatformNumber == '000040' || result[0].PlatformNumber == '000041') {   // 因为联想和其他比如标准平台注册页面内容不同   (40是预发布联想平台编号)
                        this.isLenovoPlatform = true
                    } else {
                        this.isLenovoPlatform = false
                    }

                    // 这里可能会返回多个平台，若有返回回调地址，则根据地址来判断是哪个平台
                    // 若没有回调函数还是默认第一个
                    let PlatformNumber = result[0].PlatformNumber;
                    for (let i = 0; i < result.length; i++) {
                        const item = result[i];
                        // 优化下平台对应的地址
                        let PlatformUrl = item.PlatformUrl;
                        if (item.PlatformProtocol) {
                            PlatformUrl = PlatformUrl.replace(item.PlatformProtocol, "");
                        }

                        if (redirecturi.indexOf(PlatformUrl) > -1) {
                            PlatformNumber = item.PlatformNumber;
                            // 再全局调整下平台的code
                            try {
                                let platform = JSON.parse(localStorage['platform']);
                                if (platform) {
                                    platform.PlatformSystemNumber = PlatformNumber;
                                    platform.PlatformSystemName = item.PlatformName || '';
                                    platform.ZoneIdx = item.ZoneId || '';
                                }
                                localStorage["platform"] = JSON.stringify(platform); // 将调整后的重新赋值
                            } catch (error) {
                                console.log('1', error);
                            }

                            break;
                        }
                    }
                    
                    var dateString = new Date(new Date().getTime() + 24*60*60*1000).toLocaleDateString(); // 获取明天日期
                    var nightDate = dateString + " " + "23:59:59";
                    var span = (new Date(nightDate) - new Date()) / 1000 / 3600 / 24;
                    cookie.set('accountRoleData', JSON.stringify({ "hostname": hostNameParam.hostName, "platformNumber": PlatformNumber }), { expires: span,domain: this.getDomain() })

                    let code = 'platform_' + PlatformNumber;
                    let param = {
                        codeList: [code]
                    }
                    GET_LOGIN_CONFIG(param).then((res) => {
                        if (res.data.status.success == true) {
                            if (this.isValidArray(res.data.data)) {
                                let currentConfig = res.data.data[0]  // 当前的配置
                                this.Mode = ''
                                this.config = currentConfig
                            }
                        } else {
                            this.$message.error('未获取到登录配置')
                        }
                    })
                }
            } else {
                this.$message.error('未获取到平台code');
            }
        })
        this.config = this.formworkConfig
        this.previewConfig = this.formworkConfig

        // this.config = {
        //         "code": "platform_000001",
        //         "basicConfig": {
        //             "logo": "https://fordata.forclass.net/AttachFiles/9616/2022_03_09_1646805668712.png",
        //             "logoEnableStatus": true,
        //             "loginPageText": "",
        //             "loginPageTextEnableStatus": false,
        //             "backGroundImage": "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fp6.itc.cn%2Fq_70%2Fimages03%2F20210612%2F3150e4024f6b453ab2bc34c88adaf798.png&refer=http%3A%2F%2Fp6.itc.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650693985&t=313092dfe08445a8fa6cae87421b2cd6",
        //             "forgetPasswordEnableStatus": true,
        //             "registerNowEnableStatus": true,
        //             "rememberPasswordEnableStatus": true,
        //             "icp": "京ICP备09087741号-10  京公网安备 11010802030731号",
        //             "icpEnableStatus": true
        //         },
        //         "colorConfig": {
        //             "inputBoxSelect": "#F2F3F5",
        //             "rememberPassword": "#FF6A00",
        //             "loginButton": "#FF6A00",
        //             "otherText": "#FF6A00",
        //             "loginModeSelect": {
        //                 "startColor": "#FF6A00",
        //                 "endColor": "#FFC500"
        //             },
        //             "qRcodeOrAccountIcon": {
        //                 "icon": "#FF6A00",
        //                 "backgroundColor": "rgba(255,106,0,0.10)"
        //             }
        //         },
        //         "loginModeConfigs": [
        //             {
        //                 "modeCode": "AccountLogin",
        //                 "modeName": "账号登录",
        //                 "platformEnableStatus": true,
        //                 "adminmoduleEnableStatus": false,
        //                 "personalCenterEnableStatus": true,
        //                 "order": 1
        //             },
        //             {
        //                 "modeCode": "CommandLogin",
        //                 "modeName": "口令登录",
        //                 "platformEnableStatus": false,
        //                 "adminmoduleEnableStatus": false,
        //                 "personalCenterEnableStatus": false,
        //                 "order": 2
        //             },
        //             {
        //                 "modeCode": "ShortMessageLogin",
        //                 "modeName": "短信登录",
        //                 "platformEnableStatus": false,
        //                 "adminmoduleEnableStatus": true,
        //                 "personalCenterEnableStatus": false,
        //                 "order": 3
        //             },
        //             {
        //                 "modeCode": "EmailLogin",
        //                 "modeName": "邮箱登录",
        //                 "platformEnableStatus": true,
        //                 "adminmoduleEnableStatus": true,
        //                 "personalCenterEnableStatus": false,
        //                 "order": 4
        //             },
        //             {
        //                 "modeCode": "ScanCodeLogin",
        //                 "modeName": "扫码登录",
        //                 "platformEnableStatus": false,
        //                 "adminmoduleEnableStatus": false,
        //                 "personalCenterEnableStatus": false,
        //                 "order": 5
        //             }
        //         ]
        // }


        // 从paas项目后台管理-统一登录配置：点击预览跳转到这个页面时，因为跨域所以配置页面通过postMessage向这个页面发送配置信息，加一个参数mode='preview'加以区分
        if (this.$route.query.mode && this.$route.query.mode == 'preview') {
            if (this.$route.query.type) {
                this.type = this.$route.query.type    // 登录的类型，是平台登录、管理模块登录还是个人中心登录
            } else {
                this.type = 'platform'   // 默认给成平台登录
            }
            window.removeEventListener("message", this.receiveMessage, false);
            window.addEventListener("message", this.receiveMessage, false);
        }
    },
    beforeMount() {
    },
    mounted() {
        this.$bus.$on('QRCodeShowChooseRole', (res) => {      // 二维码扫码登录成功之后，展示角色选择，同时在SignIn.vue里切换到哪个普通的登录页面，如果还在扫码登录页面无法显示出来角色选择
            // console.log("获取到了扫码二维码之后的角色信息",res);
            this.isScanCode = false
            this.showTopRightCorner = false;
        })
        // console.log("路由信息",this.$route)
        this.$bus.$on('QRCodeBindingRole', (res) => {      // 二维码扫码初次绑定账号成功之后展示角色选择，同时在SignIn.vue里切换到哪个普通的登录页面，如果还在扫码登录页面无法显示出来角色选择
            // console.log("获取到了扫码二维码之后的角色信息",res);
            this.isScanCode = false  // 微信绑定成功之后显示到登录页面的选角色
            this.showTopRightCorner = false;
        })
        this.$bus.$on('chooseAccount', res => {
            this.showTopRightCorner = false;
        })
    }
}
</script>
<style lang="scss" scoped>
.signin {
    // 统一登录
    // background-color: #87ceeb82;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    // background-image: url(../../../public/Account/images/signin/backgroundImg.png);
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    display: flex;
    flex-direction: column;

    &.bg {
        background-color: #F6F6F6;
    }

    // justify-content: center;
    .container {
        // 插画和登录区域内容
        // width: 1350px;
        margin-right: 16%;
        height: 647px;
        // background-color: #87ceeb96;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;

        .login {
            // 右侧登录区域
            width: 472px;
            height: 645px;
            background-color: #FFFFFF;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            // box-shadow: 0 20px 50px 0 #f4f4f5;

            // box-shadow: 0 0px 8px #f4f4f5;
            // box-shadow: 0 20px 50px 0 #f6f6f6;
            .login-content {
                display: flex;
                flex-direction: column;
                align-items: center;

                .toggle-view {
                    // 正常登录和扫码登录切换
                    width: 56px;
                    height: 56px;
                    position: absolute;
                    left: calc(100% - 56px);
                    cursor: pointer;

                    img {
                        width: 100%;
                    }
                }

                .logo {
                    // 登录区域的logo
                    max-width: 100%;
                    height: 23px;
                    margin-top: 56px;
                    margin-bottom: 46px;

                    .logo-img {
                        // logo图标
                        width: 210px;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .logo-text {
                        font-size: 24px;
                        font-weight: 600;
                    }
                }
            }

            .footer {
                pre {
                    font-size: 12px;
                    line-height: 20px;
                    height: 20px;
                    color: #A9AEB8;
                    font-weight: 400;
                    margin-bottom: 16px;
                    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
                }
            }
        }
    }

    .keyboard-mask {
        position: absolute;
        width: 42%;
        left: 10px;
        bottom: 50px;
    }
}

// H5移动端
@media screen and (max-width: 768px) {
    .signin {
        justify-content: center;
        background: #fff !important;

        .container {
            margin-right: 0;
            width: 100%;
            height: 100%;
            padding: 0 20px;
            box-sizing: border-box;

            .login {
                width: 100%;

                // height: 100%;
                .login-content {
                    width: 85%;

                    .toggle-view {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>