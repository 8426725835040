<template>
    <div class="index-page">
        <!-- <div v-loading="loading" class="loading"></div> -->
    </div>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            loading: true, // loading
            appid: '5df95abe92034cfc9a88524154cad5d4',
        }
    },
    async created() {
        // 跳转到宁转云的登录页面
        const authUrl = 'https://auth2.nxeduyun.com/oauth2/v1/connect/authorize';
        const redirectUri = `${location.origin}/NxeDuYun/ZznAuth`;
        const state = this.$route.query.state || ''; // win-windows端【默认】 | android-安卓端
        const appid = this.$route.query.appid || this.appid; // appid可以通过链接参数但也有默认值
        localStorage.setItem('nxeduyunState', state);
        window.location.href = `${authUrl}?grant_type=authorization_code&response_type=code&client_id=${appid}&redirect_uri=${redirectUri}&state=${state}`;
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;

    .loading {
        width: 100%;
        height: 100%;
    }
}
</style>