<template>
<div>
    <el-dialog class="dialog" title="登录" :visible.sync="loginConfirmDialog" :width="'400px'" :before-close="closeLoginDialog" :close-on-click-modal="false">
        
        <div class="uc-login-container">
            <!-- 切换登录方式为扫码登录按钮开始 -->
            <!-- <div class="switch-enterkey-wechat-btn wechat" :class="{'pc': this.showWeChatLogin}" @click="onShowWeChatToggleClick"></div> -->
            <!-- 切换登录方式为扫码登录按钮结束 -->
            
            <!-- 密码登录、口令登录 -->
            <div class="enterkey-login-pane" v-show="!showWeChatLogin">
                <div class="login-navs" v-if="enterKeyMethods.length > 0">
                    <div class="loginnav-item" 
                        :class="{'active': enterKeyActiveMethod == item.method}" 
                        v-for="(item,index) in enterKeyMethods" :key="index"
                        @click="onEnterKeyMethodClick(item)">
                        <div class="nav-title">{{ item.showText }}</div>
                        <div class="nav-active-arc-line"></div>
                    </div>
                </div>
                <div class="current-user">你好，{{userName}}</div>
                <div class="login-content password" :class="{'hide_important': enterKeyActiveMethod != 'password'}">
                    <el-input v-model="confirmLoginPwd" placeholder="请输入密码" @keyup.enter.native="confirmLogin('password')" type="password" clearable></el-input>
                    <el-button class="password-submit" type="primary" @click="confirmLogin('password')">登 录</el-button>
                </div>
                <div class="login-content token" :class="{'hide_important': enterKeyActiveMethod != 'token'}">
                    <el-input v-model="confirmLoginToken" placeholder="请输入临时口令" @keyup.enter.native="confirmLogin('token')" type="text" clearable></el-input>
                    <el-button class="password-submit" type="primary" @click="confirmLogin('token')">登 录</el-button>
                </div>
            </div>
            <!-- 微信扫码登录 -->
            <iframe v-show="showWeChatLogin" class="wxlogin-frame" :src="weChatLoginUrl"></iframe>
        </div>
    </el-dialog>
</div>
</template>

<script>
import {env} from '@/http/env.js'
import {GET_ACCOUNT_SEED, LOGIN_ACCOUNT_JSON, GET_ACCOUNT_SESSION_BY_SECURITYPASSWORD} from '@/http/api.js'
import '@/styles/element-variables.scss';   // element-ui主题色
export default {
    props:['loginConfirmDialog'],
    data() {
        return {
            enterKeyMethods: [
                { "method": "password", "showText": "账号登录" },
                // { "method": "token",    "showText": "口令登录" },
            ],
            confirmLoginPwd:'',      // 验证登录时输入的密码     
            confirmLoginToken: '',   // 验证登录时输入的临时口令
            enterKeyActiveMethod: "password", // 目前的键盘输入方式（password、token）
            showWeChatLogin: false,  // 是否显示微信登录
            weChatLoginUrl: ""
        }
    },
    watch: {
        showWeChatLogin: {
            handler(newVal){
                if(newVal && this.weChatLoginUrl == ""){
                    this.weChatLoginUrl = this.getWechatLoginUrl()
                }
            },
            immediate: true
        }
    },
    computed:{
        userName(){
            return this.loadAccount().realname
        }
    },
    methods:{
        // 切换登录方式（微信登录）
        onShowWeChatToggleClick(){
           this.showWeChatLogin = !this.showWeChatLogin
        },
        // 切换登录方式（账号密码登录）
        onEnterKeyMethodClick(item){
            this.enterKeyActiveMethod = item.method
        },
        confirmLogin(type){  // 验证登录
            if(type == 'password'){
                // var identity = sessionStorage["stamp"] || new Date().getTime();
                // sessionStorage["stamp"] = identity;
                // 和登录那块的stamp最好区分开
                var identity = sessionStorage["userCenterStamp"] || new Date().getTime();   
                sessionStorage["userCenterStamp"] = identity;
                GET_ACCOUNT_SEED(this.$qs.stringify({stamp: identity})).then((response)=>{
                    var result = response.data 
                    var key = result.key;
                    var iv = result.iv;
                    var loginParam = {
                        'account': this.encrypt(this.loadAccount().loginname, key, iv).replace(/\+/g, '%2B'),
                        'password': this.encrypt(this.confirmLoginPwd, key, iv).replace(/\+/g, '%2B'),
                        'xStr': JSON.stringify({ stamp: identity })
                    };
                    // var loginParam = {
                    //     'account': this.encrypt(this.loadAccount().loginname, key, iv),
                    //     'password': this.encrypt(this.confirmLoginPwd, key, iv),
                    //     'xStr': JSON.stringify({ stamp: identity })
                    // };
                    //console.log("个人中心二次登录-账号",this.loadAccount().loginname);
                    //console.log("个人中心二次登录-密码",this.confirmLoginPwd);
                    //console.log("个人中心二次登录-stamp",identity);
                    LOGIN_ACCOUNT_JSON(this.$qs.stringify(loginParam)).then((response)=>{
                        if (!this.isValid(response.data) || !this.isValidArray(response.data.result)) {
                            // debugger
                            var result = response.data
                            var errmsg = "当前密码不正确，请重试";
                            var retTxt = result.ReturnText;
                            switch (result.ReturnCode) {
                                case 0:
                                    if (retTxt == "Too much failures") {
                                        errmsg = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试";
                                    }
                                    if (retTxt == "Invalid request") {
                                        errmsg = "请勿频繁操作";
                                    }
                                    break;
                                case -101:
                                    errmsg = "此用户名不存在";
                                    break;
                                case -102:
                                    errmsg = "用户名或密码不正确";
                                    break;
                                case -103:
                                    errmsg = "此账户已被禁用，请联系管理员";
                                    break;
                                case -104:
                                    errmsg = "会话无效，请联系管理员";
                                    break;
                                default:
                                    errmsg = "当前密码不正确，请重试";
                                    break;
                            }
                            this.$message.error(errmsg);
                            this.$bus.$emit("confirmLoginSucc",'failure')  // 说明验证失败了
                        }else{
                            this.$bus.$emit("confirmLoginSucc",'success')  // 说明验证成功了
                        }
                    })
                })
            }
            if(type == 'token'){
                // 此处只需要验证身份即可 接口正常返回就可以了
                let loginParam = {
                    securitypassword: this.confirmLoginToken,
                    page: 1
                }
                GET_ACCOUNT_SESSION_BY_SECURITYPASSWORD(this.$qs.stringify(loginParam)).then((response)=>{
                    // 判断口令是否正常
                    //console.log("口令登录返回值",response);
                })
            }
            // this.loginConfirmDialog = false  // 关闭输入密码弹窗
        },
        closeLoginDialog(){  // 关闭验证密码的弹窗（跳到班级管理和设备管理的时候会弹出）
            // 关闭的时候重置状态
            this.confirmLoginPwd = ""
            this.confirmLoginToken = ""
            this.$emit("closeLoginDialog")
            // this.loginConfirmDialog = false
            // this.$bus.$emit("updateCurrentTag",0)  // 失败的时候还是点击修改资料标签
        },
        cancelLoginDialog(){  // 取消弹窗
            this.$emit("cancelLoginDialog")
            // this.loginConfirmDialog = false
            // this.$bus.$emit("updateCurrentTag",0)  // 失败的时候还是点击修改资料标签
        },
        // 获取微信扫码登录iframe url
        getWechatLoginUrl(){
            // 平台编号
            var ptNum = "";
            // iframe 地址
            var src = "";
            if(env == "test"){
                ptNum = '000001';
                src = location.protocol + "//test-ws.forclass.net/wxlogin.html";
            }
            if (env == 'production') {
                ptNum = platform.PlatformSystemNumber;
                
                // 若为河南项目，需要使用河南项目的微信登录地址
                if(location.host.indexOf('hasmartedu.com') > -1){
                    src = location.protocol + "//webservice-jzjx.hasmartedu.com/wxlogin.html";
                }else{
                    src = location.protocol + "//webservice.forclass.net/wxlogin.html";
                }
            }
            var time = new Date().getTime();
            var acnt = this.loadAccount();
            var loginName = acnt.loginname ? acnt.loginname : acnt.account;
            src += '?ptnum=' + ptNum + '&loginname=' + loginName + '&t=' + time;
            return src
        },
        // 扫码登录消息接收消息回调
        receiveMessage(e){
            var data = e.data;
            if (data) {
                if(data.cmd == 'pass') {
                    var acnt = data.param.user;
                    // console.log("wechat account: ", acnt);
                    // 验证成功
                    if (acnt.userid == this.loadAccount().userid) {
                        
                    } else {
                        // 验证失败
                        this.weChatLoginUrl = this.getWechatLoginUrl()
                    }
                }
            }
        }
    },
    mounted(){
        // 每次打开组件的时候重置状态
        // console.log("打开二次输入密码框了")
        this.confirmLoginPwd = ""
        this.confirmLoginToken = ""
        // 扫码登录消息接收
        window.removeEventListener("message", this.receiveMessage, false);
        window.addEventListener("message", this.receiveMessage, false);
    },
}
</script>

<style lang="scss">
.dialog .el-dialog__body{
    padding-top: 10px;
}
.uc-login-container {
    position: relative;
    box-sizing: border-box;
    .loginnav-item{
        text-align: center;
        font-size: 14px;
        color: #6B7785;
        text-align: center;
        font-weight: 400;
        position: relative;
        display: inline-block;
        margin-right: 24px;
        cursor: pointer;
        .nav-active-arc-line{
            width: 34px;
            height: 10px;
            margin: 4px auto 0;
            background-image: url(~@Account/images/signin/tabSelected.png);
            background-size: 34px 10px;
            display: none;
        }
    }
    .enterkey-login-pane {
        .current-user{
            margin-top: 24px;
            color: #1D2129;
            font-size: 16px;
            margin-bottom: 32px;
            font-weight: 600;
        }
    }
    .loginnav-item.active{
        font-weight: 600;
        color: #1D2129;
        .nav-active-arc-line{
            display: block;
        }
    }
    .password-submit{
        width: 100%;
        margin-top: 20px;
    }
    .switch-enterkey-wechat-btn{
        position: absolute;
        right: 0;
        background-image: url(~@Account/images/signin/scanLogin.png);
        background-size: contain;
        width: 56px;
        height: 56px;
        cursor: pointer;
    }
    .switch-enterkey-wechat-btn.pc{
        background-image: url(~@Account/images/signin/PCLogin.png);
    }
    .wxlogin-frame{
        border: 0;
        width: 400px;
        max-width: 100%;
        height: 310px;
        margin-top: 35px;
    }
}
.hide_important{
    display: none!important;
}
</style>