<template>
  <div class="module-select-container">
    <div class="nav-header clearfix">
      <div class="layout-select pull-left">联想教学教研</div>
      <div class="pull-right"> 
        <div class="use-guide" @click="openUserHelp">使用指南</div>
        <div class="login-btn" @click="onLoginnClicked">登录/注册</div>
      </div>
    </div>
    <div class="body-content">
      <div class="content-wrap clearfix">
        <div class="left-wrap pull-left">
          <div class="left-wrap-content">
            <div class="system-name">
              <div class="system-name-text">联想教学教研</div>
              <span></span>
            </div>
            <div class="system-discrition">
              <p>联想教学教研主要包含班级管理和智慧教研两块。</p>
              <p>班级管理：游戏化教学特色，丰富课堂互动，多维度学生评价，助力教师高效管理家校关系，携手家长共育。</p>
              <p>智慧教研：查看教学全过程数据，参与集体备课，扫码听课评课，助力教师专业成长。</p>
            </div>
            <div class="download-wrap">
              <el-dropdown trigger="click" placement="bottom">
                <div class="download-btn">立即下载</div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-link :href="configInfo.webclinet64" target="_blank" :underline="false">下载64位</el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link :href="configInfo.webclinet32" target="_blank" :underline="false">下载32位</el-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div class="device-btn">Windows客户端</div>
            </div>
          </div>
        </div>
        <div class="right-wrap pull-right"></div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import {GETCONFIGINFO} from "@/http/api";
import Footer from '@/components/signin/Widgets/Footer.vue';
export default {
  data() {
    return {
      configInfo: {}
    };
  },
  components: { Footer },
  computed: {
    
  },
  watch: {
  },
  methods: {
    onLoginnClicked(){
      this.$router.push('/Account/SignIn')
    },
    onDownloadClicked(){
      location.href = "https://fordata.forclass.net/lenovo/client/lenovoedu_Setup_1.0.220825.exe"
    },
    openUserHelp(){   
      location.href = window.location.origin + `/Bg/clientHelp?index=0&system=lx`;
    },
    getConfig(){
      let sendKey = window.location.host;
      if (window.location.host.includes("localhost") || window.location.host.includes("8080")) {
        sendKey = "educenter-lenovo.forclass.net"; //lenovo
        // sendKey = "test-ca.forclass.net";  //forclass
      }
      GETCONFIGINFO({ recourseKey: sendKey, recourseType: 'ClassAssistant' }).then(res => {
        let result = res.data.data.resultText;
        if (result) this.configInfo = result;
      })
    },
  },
  created() {
    this.getConfig();
  },
};
</script>
<style lang="scss">
.module-select-container {
  width: 100%;
  height: 100vh;
  .nav-header {
    height: 56px;
    line-height: 56px;
    background: #FFFFFF;
    padding: 0 160px;
    .layout-select{
      color: #FF6A00;
      font-weight: 600;
      font-size: 20px;
    }
    .login-btn{
      width: 110px;
      height: 32px;
      margin-top: 12px;
      border-radius: 16px;
      border: 1px solid #FF6A00;
      line-height: 30px;
      box-sizing: border-box;
      text-align: center;
      color: #F15F00;
      cursor: pointer;
    }
  }
  .body-content {
    padding: 0 160px;
    background: #ffffff url("~@Account/images/signin/lenovoModuleBackground.png")
      no-repeat;
    background-size: 100% 100%;
    height: calc(100vh - 56px);
    min-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-wrap{
      width: 100%;
      height: 700px;
      .left-wrap{
        width: 44%;
        height: 100%;
        display: flex;
        align-items: center;
        .left-wrap-content{
          margin-top: -100px;
          .system-name{
            color: #131414;
            font-size: 32px;
            position: relative;
            z-index: 1;
            margin-bottom: 40px;
            .system-name-text{
              position: relative;
              z-index: 1;
              font-weight: 600;
            }
            span{
              display: block;
              position: absolute;
              left: -12px;
              top: -2px;
              width: 24px;
              height: 24px;
              background-image: url("~@Account/images/download/round_icon.png");
              background-repeat: no-repeat;
              background-position: 0 0;
              background-size: 100%;
              z-index: 0;
            }
          }
          .system-discrition{
            color: #606266;
            p{
              margin-bottom: 24px;
              font-size: 16px;
            }
            p:last-child{
              margin-bottom: 0;
            }
          }
          .download-wrap{
            display: flex;
            margin-top: 160px;
            .download-btn{
              width: 192px;
              height: 56px;
              line-height: 56px;
              background: #FF6A00;
              box-shadow: 0px 6px 12px rgba(255, 106, 0, 0.32);
              border-radius: 28px;
              padding-left: 70px;
              box-sizing: border-box;
              font-size: 20px;
              color: #ffffff;
              background-image: url("~@Account/images/download/download_icon.png");
              background-size: 24px;
              background-position: 36px 15px;
              background-repeat: no-repeat;
              cursor: pointer;
            }
            .device-btn{
              font-size: 16px;
              color: #606266;
              height: 56px;
              line-height: 56px;
              margin-left: 26px;
              background-image: url("~@Account/images/download/win_icon.png");
              background-size: 24px;
              background-position: 10px 15px;
              background-repeat: no-repeat;
              padding-left: 40px;
              box-sizing: border-box;
            }
          }
        }
      }
      .right-wrap{
        width: 54%;
        height: 100%;
        background-image: url("~@Account/images/download/content_cover.png");
        background-size: contain;
        background-position: center 40%;
        background-repeat: no-repeat;
      }
    }
  }
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
  display: flex;
  justify-content: center;
  .use-guide{
    margin-right: 48px;
    color: #131414;
    cursor: pointer;
  }
}
.clearfix {
  clear: both;
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled) {
  &:hover {
    background-color: rgba(#FF6A00, .1);
  }
  a:hover {
    color: #FF6A00;
  }
}
</style>
