<template>
    <div class="index-page">
        <!-- <div v-loading="loading" class="loading"></div> -->
    </div>
</template>

<script>
import {
    NECIBOOK_GET_USERCENTER
} from '@/http/api.js';
export default {
    name: 'Index',
    data() {
        return {
            loading: true, // loading
            appid: '1823981868110970882',
            proAppid: '1849296964502470658',
        }
    },
    async created() {
        // 跳转到中转云的登录页面
        let env = this.getEnv();
        // let env = 'production';
        const authUrl = `${env === 'test' ? 'https://testbasecamp' : 'http://basecamp'}.necibook.com/api/auth/api/v1/oauth/authorize`;
        const redirectUri = `${location.origin}/Necibook/Auth`;
        const state = this.$route.query.state || ''; // win-windows端【默认】 | android-安卓端
        const appid = this.$route.query.appid || (env === 'test' ? this.appid : this.proAppid); // appid可以通过链接参数但也有默认值
        localStorage.setItem('necibookState', state);
        window.location.href = `${authUrl}?response_type=code&client_id=${appid}&redirect_uri=${redirectUri}&redirect_flag=true&state=${state}`;
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;

    .loading {
        width: 100%;
        height: 100%;
    }
}
</style>