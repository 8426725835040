<template>
  <div class="relation-account">
    <div class="title">
      <div>
          <span>关联备授课账号</span>
      </div>
      <div>
          <el-button v-if="isClient()" type="primary" size="mini" @click="goBack">返回</el-button>
      </div>
    </div>
    <el-divider class="line"></el-divider>
    <div class="content">
      <iframe v-if="!isRelation" id="accountIfa" :src="accountSrc" frameborder="0"></iframe>
      <div v-else class="account-info">
        <div>已关联的备授课账号：{{ accountNum }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  AUTHORIZATION_LENOVO_NEW
} from '@/http/api.js';
import { mapState } from 'vuex';
import { Base64 } from 'js-base64';
export default {
  name: 'RelationAccount',
  props: [],
  components: {},
  data() {
    return {
      isRelation: false, // 是否关联
      accountNum: '', // 备授课账号

      accountSrc: '', // 弹窗url
      appId: 'teaching_class',
      deviceType: 'web-browser',
    };
  },
  watch: {
    lenovoAccount: {
      handler(newVal) {
        if(newVal.sourceLoginName) {
          this.isRelation = true;
          this.accountNum = newVal.sourceLoginName;
        } else {
          this.isRelation = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      lenovoAccount: 'lenovoAccount'
    }),
    account() {
      return this.loadAccount();
    }
  },
  methods: {
    isClient(){
      return this.$route.query.path ? true :false
    },
    // 返回
    goBack() {
        let query = this.$route.query;
        location.href = `${location.origin}${query.path}`;
    },
    // 获取url
    onGetAccountUrl() {
      let protocol = location.protocol;
      this.accountSrc = `${protocol}//eudp.smartedu.lenovo.com/auth-ui/#/app/login-web?appId=${this.appId}&deviceType=${this.deviceType}`;
      window.addEventListener("message", this.lenovoLogin);
    },
    lenovoLogin(obj) {
      let data = obj.data;
      if(data && data.data && data.data.userId) {
        this.onRelationAccount(data);
      }
      window.removeEventListener("message", this.lenovoLogin, true);
    },
    onRelationAccount(info) {
      let data = info.data;
      let params = {
        createTime: data.createTime,
        lang: data.lang,
        redirectUrl: data.redirectUrl,
        refreshExpire: data.refreshExpire,
        refreshToken: data.refreshToken,
        state: data.state,
        token: data.token,
        tokenExpire: data.tokenExpire,
        userId: data.userId,
        forclassuserid: this.account.userid
      }
      let cmdInfo = Base64.encode(JSON.stringify(params));
      AUTHORIZATION_LENOVO_NEW({ cmdInfo, session: this.getSession() }).then(res => {
        if(res.data.ReturnCode == 1) {
          console.log(res);
          this.$message.success('关联成功!');
          let data = res.data;
          this.accountNum = data.result[0].SourceLoginName;
          this.isRelation = true;
        } else {
          this.$message.error(res.data.ReturnText);
        }
      })
    }
  },
  created() {
    this.onGetAccountUrl();
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss'; 
.relation-account {
  background-color: $bg-color2;
  height: 960px;
  width: 100%;
  border-radius: 4px;
  cursor: default;
  .title {
    padding: 24px 20px 0 32px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .line {
    margin: 15px 0 32px;
  }
  .content {
    padding: 0 20px 0 32px;
    margin-top: 32px;
    width: 100%;
    height: calc(960px - 93px);
    box-sizing: border-box;
    #accountIfa {
      width: 100%;
      height: 100%;
    }
    .account-info {
      div {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
