import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
import MD5 from 'js-md5'
import { accountUrl, accountWsUrl, webapiUrl, newWebApiUrl, necibookbApiUrl, nxeduyunbApiUrl} from "./env"

// 设置请求最大时长
axios.defaults.timeout = 50000

// 请求拦截器
let reqNum = 0
axios.interceptors.request.use(config => {
  let _headers = config.headers
  if (_headers.loading != "silence"){
    reqNum++
    Vue.prototype.bus.$emit('loading', true);
  }else{
    delete config.headers.loading
  }

  // 如果是 application/json 请求 不需要 qs.stringify 
  // 此处用 qs.parse 转换回去
  let _contentType = _headers['Content-Type']
  if(_contentType == "application/json"){
    let _data = config.data
    if(typeof _data == "string"){
      config.data = qs.parse(config.data)
    }
  }
  
  const hostname = window.location.hostname

  // if(hostname === 'localhost'){
  //   if(config.data){
  //     config.data.hostname = 'test-account.forclass.net';
  //   }

  //   if(config.params){
  //     config.params.hostName = 'test-account.forclass.net';
  //   }
  // }
  
  if (hostname != "localhost" && hostname == "educenter-lenovo.forclass.net") {
    let isAddSignature = false
    // ==== 更改域名 ==== 
    const hostPathReplacementRule = {
      // "/UserCenter/": "/ApiLenovo/UserCenter/",
      // "/roleapi/": "/ApiLenovo/roleapi/",
      // "/MasterDataCenter/": "/ApiLenovo/MasterDataCenter/",
      // "/classassistant/": "/ApiLenovo/classassistant/",
      // "/LenovoTeachingResearch/": "/ApiLenovo/LenovoTeachingResearch/",
      // "/Lenovoresourcebusiness/": "/ApiLenovo/Lenovoresourcebusiness/",
      // "/ApiLessonSt/": "/ApiLenovo/ApiLessonSt/",
      // "/api/Tpk/": "/ApiLenovo/api/Tpk/",
      // "/Account/": "/ApiLenovo/Account/",
      // "/PublicToolSCenter/": "/ApiLenovo/PublicToolSCenter/",

      // "/ConsoleService_Region.asmx/": "/ServiceLenovo/ConsoleService_Region.asmx/",
      // "/ANAService.asmx/": "/ServiceLenovo/ANAService.asmx/",
      // "/AccountService.asmx/": "/ServiceLenovo/AccountService.asmx/",
      // "/CommService.asmx/": "/ServiceLenovo/CommService.asmx/",
      // "/PaasService.asmx/": "/ServiceLenovo/PaasService.asmx/",
      // "/ConsoleService.asmx/": "/ServiceLenovo/ConsoleService.asmx/",
    }
    const url = config.url
    for (var k in hostPathReplacementRule) {
      var propKey = k;
      var propValue = hostPathReplacementRule[propKey]
      if (url.indexOf(propKey) >= 0) {
        isAddSignature = true
        // 分成两份 
        // 数组第0项 域名
        // 数组第1项 具体接口
        var tempArr = url.split(propKey)
        tempArr[0] = window.location.origin // 接口主域名替换为当前域名
        tempArr.splice(1, 0, propValue) // 数组中间插入当前替换业务path
        config.url = tempArr.join("")
        break
      }
    }

    if (isAddSignature){
      // ==== 增加验证头 ==== 
      // step1: 取当前时间戳
      let timeStamp = new Date().getTime()
      // step2: 取当前用户session 没有用户session用唯一id代替（伪）
      let sessionStr = Vue.prototype.getSession()
      if (!sessionStr || sessionStr == "") {
        sessionStr = Vue.prototype.uniqueid()
      }
      // guid
      let gateway_guid = Vue.prototype.uniqueid()
      // 根据时间戳奇偶确定如何连接内容和链接位置
      // step3: 获取时间戳的一半
      let joinContent = String(timeStamp / 2)
      // step4: 将获取到的时间戳的一半 MD5 
      let joinContentMd5Value = MD5(joinContent).toUpperCase()
      // step5: 将sessionStr MD5 
      let objectContentMd5Value = MD5(sessionStr + timeStamp + gateway_guid).toUpperCase()
      // step6：根据时间戳的奇偶确定如何链接两个 md5
      // 等于0 MD5加密链接内容+MD5加密得对象 ；不等于0 MD5加密对象+MD5加密链接内容
      let joinIndex = timeStamp % 2
      let encryptionContent = null
      if (joinIndex == 0) {
        encryptionContent = joinContentMd5Value + objectContentMd5Value
      } else {
        encryptionContent = objectContentMd5Value + joinContentMd5Value
      }
      // step7: 再次将拼合好的 md5 链接字符串进行 md5 
      let encryptionContentMd5Value = MD5(encryptionContent).toUpperCase()
      // step8: 生成随机10个字符拼接到 encryptionContentMd5Value 前面
      let uniqueid = Vue.prototype.uniqueid()
      uniqueid = uniqueid.slice(0, 10)
      encryptionContentMd5Value = uniqueid + encryptionContentMd5Value

      config.headers["gateway_guid"] = gateway_guid
      config.headers["gateway_session"] = sessionStr
      config.headers["signature"] = encryptionContentMd5Value
      config.headers["timestamp"] = timeStamp
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(response => {
  reqNum--
  if (reqNum <= 0) {
    Vue.prototype.bus.$emit('loading', false);
  } else {
    Vue.prototype.bus.$emit('loading', true);
  }
  if (response.status === 200) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(response)
  }
})

// 请求地址
export const getBaseUrl = function(type, url){
  let baseUrl = accountUrl
  switch(type){
    case "account":
      baseUrl = accountUrl
      break
    case "accountWs":
      baseUrl = accountWsUrl
      break
    case "webapi":
      baseUrl = webapiUrl
      break
    case "newWebapi":
      baseUrl = newWebApiUrl
      break
    // 中教云授权登录
    case "necibookbApi":
      baseUrl = necibookbApiUrl
      break
    case 'nxeduyunbApi':
      baseUrl = nxeduyunbApiUrl
      break
  }
  if(url && url != ""){
    return baseUrl + url
  }else{
    return baseUrl
  }
}

// 获取数据
const getResultData = function(data) {
  // account 接口
  if (typeof data.result != 'undefined' && data.result != null) {
      return data.result
  }
  // accountWs 接口
  if (typeof data.data != 'undefined' && data.data != null) {
      return data.data
  }
  return data
}

// 检查响应
const checkResponse = function(data) {
  let success = false
  // webservice 接口
  if (data.hasOwnProperty("ReturnCode")){
    if (typeof data.ReturnCode != 'undefined' && data.ReturnCode != null) {
      success = data.ReturnCode == 1
    }
  }
  // live 接口
  if (data.hasOwnProperty("msg")){
    if (typeof data.msg != 'undefined' && data.msg != null) {
      success = data.msg == 'success'
    }
  }
  // webapi 接口
  if (data.hasOwnProperty("status")){
    if (typeof data.status != 'undefined' && data.status != null) {
      success = data.status.code == '200'
    }
  }
  return success
}

// 响应处理
const responseHandler = function(response, resolve, reject){
    // console.log(response)
    // var data = Vue.prototype.$x2js.xml2js(response.data).ResultInfo   // xml转JSON
    resolve(response)
//   var data = response.data
//   if (data) {
//     // 登出接口
//     if(data == 'success'){
//       resolve(data)
//     }else{
//       if (checkResponse(data)) {
//         var result = getResultData(data)
//         resolve(result)
//       } else if (rc < 1) {
//         var result = getResultData(data)
//         reject(result)
//       }
//     }
//   } else {
//     reject(-1)
//   }
}

// 错误处理
const errorHandler = function(error, reject){
 console.log(error)
//   reqNum--
//   if (reqNum <= 0) {
//     Vue.prototype.bus.$emit('loading', false);
//   } else {
//     Vue.prototype.bus.$emit('loading', true);
//   }
  reject(-1)
}

// get请求
export function get (url, data, headers, type, response) {
  return new Promise((resolve, reject)=>{
    axios({
      url: getBaseUrl(type, url),
      method: "get",
      params: data,
      headers: headers || {
        "session": Vue.prototype.getSession(),
        "Content-Type": 'application/x-www-form-urlencoded'
      },
      responseType: response ? response : 'json'
    }).then((response)=>{
      responseHandler(response, resolve)
    }).catch((error)=>{
      errorHandler(error, reject)
    })
  })
}
// post请求
export function post (url, data, headers, type) {
  return new Promise((resolve, reject)=>{
    axios({
      // "url": url,
      "url": getBaseUrl(type, url),
      "method": "post",
      "data": data,
      "headers": headers || {
        "session": Vue.prototype.getSession(),
        // "Content-Type": 'application/json'
        "Content-Type": 'application/x-www-form-urlencoded'
      }
    }).then((response)=>{ 
      responseHandler(response, resolve, reject)
    }).catch((error)=>{
      errorHandler(error, reject)
    })
  })
}
// postJSON请求
export function postJSON (url, data, headers, type) {
  return new Promise((resolve, reject)=>{
    axios({
      "url": getBaseUrl(type, url),
      "method": "post",
      "data": data,
      "headers": headers || {
        "session": Vue.prototype.getSession()
      }
    }).then((response)=>{
      responseHandler(response, resolve)
    }).catch((error)=>{
      errorHandler(error, reject)
    })
  })
}
// postMultiData请求
export function postMultiData (url, data, type) {
  return new Promise((resolve, reject)=>{
    axios({
      "url": getBaseUrl(type, url),
      "method": "post",
      "data": data,
      "headers": {
        "session": Vue.prototype.getSession(),
        'Content-Type': "multipart/form-data"
      }
    }).then((response)=>{
      responseHandler(response, resolve)
    }).catch((error)=>{
      errorHandler(error, reject)
    })
  })
}