let env = "production"; // production test
let hostname = location.hostname
if(hostname == "educenter.lenovo.com"){
  env = "production";
}

// const protocol = location.protocol;   // http or https
const protocol = 'https:';   // http or https
let accountUrlObject = {
    // 'test': protocol + '//localhost:80',
    'test': protocol + '//test-zzn.forclass.net/',
    'production': protocol + '//zzn.forclass.net/',
}
let accountWsUrlObject = {
    'test': protocol + '//test-ws.forclass.net/',
    'production': protocol + '//webservice.forclass.net/'
}
let webapiUrlObject = {
    'test': protocol + '//test-api.forclass.net/',
    'production': protocol + '//api.forclass.net/'
}
let newWebApi = {
    'test': protocol + '//test-api.forclass.net/',
    'production': protocol + '//api.forclass.net/'
}

// 中教云授权登录接口地址
let necibookbApi = {
    'test': protocol + '//test-fc.forclass.net/',
    // 'test': 'http://test.fc.eduzibo.com/',
    // 'production': protocol + '//fc.forclass.net/',
    'production': protocol + '//www.forclass.net/',
}
//宁教云授权登录接口地址
let nxeduyunbApi = {
    'test': protocol + '//test-api.forclass.net/',
    'production': protocol + '//api.forclass.net/'
}

// 若当前为河南项目时，需要更换接口域名
if(location.host.indexOf('hasmartedu.com') > -1){
    accountUrlObject = {
        // 'test': protocol + '//localhost:80',
        'test': protocol + '//zzn-jzjx.hasmartedu.com/',
        'production': protocol + '//zzn-jzjx.hasmartedu.com/',
    }
    accountWsUrlObject = {
        'test': protocol + '//webservice-jzjx.hasmartedu.com/',
        'production': protocol + '//webservice-jzjx.hasmartedu.com/'
    }
    webapiUrlObject = {
        'test': protocol + '//api-jzjx.hasmartedu.com/',
        'production': protocol + '//api-jzjx.hasmartedu.com/'
    }
    newWebApi = {
        'test': protocol + '//api-jzjx.hasmartedu.com/',
        'production': protocol + '//api-jzjx.hasmartedu.com/'
    }
    necibookbApi = {
        'test': protocol + '//test-fc.forclass.net/',
        // 'test': 'fc-jzjx.hasmartedu.com',
        'production': protocol + '//fc-jzjx.hasmartedu.com/'
    }
}


const accountUrl = accountUrlObject[env]
const accountWsUrl = accountWsUrlObject[env]
const webapiUrl = webapiUrlObject[env]
const newWebApiUrl = newWebApi[env]
const necibookbApiUrl = necibookbApi[env]
const nxeduyunbApiUrl = nxeduyunbApi[env]
export { env, accountUrl, accountWsUrl, webapiUrl, newWebApiUrl, necibookbApiUrl, nxeduyunbApiUrl}