<template>
    <div class="index-page">
        <!-- <div v-loading="loading" class="loading"></div> -->
    </div>
</template>

<script>
import {
    NxeDuYun_GET_USERCENTER
} from '@/http/api.js';
export default {
    name: 'Auth',
    data() {
        return {
            loading: true, // loading
        }
    },
    created() {
        const code = this.$route.query.code;
        // 通过返回的code来获取session
        this.getSessionByCode(code);
    },
    methods: {
        async getSessionByCode(code) {
            const state = localStorage.getItem('nxeduyunState') || 'win';
            const njyId = 1; // 0综合评价平台,1采集助手
            try {
                const res = await NxeDuYun_GET_USERCENTER({ code , njyId});
                let data = res.data || {};
                

                if(typeof data === 'string'){
                    data = JSON.parse(data);
                }

                console.log('返回的参数：', data);

                let session = '';
                let error = ''; // 是一个字符串错误提示内容；
                let result = 0; // 0 成功， 1， 失败;
                if (data.ReturnCode == 1) {
                    /**
                     * 反馈客户端：
                     * result : 0 成功， 1， 失败;
                     * error: 是一个字符串错误提示内容；
                     * */
                    session = data.result[0];
                    error = data.ReturnText || '';
                } else {
                    result = 1;
                    session = '';
                    error = data.ReturnText || '登录失败';
                }
                
                let env = this.getEnv(); // 获取当前的环境变量
                if(env == 'test'){
                    location.href = `https://test-ce.forclass.net/Memo/RoleChoice?type=ssj&session=${session}&dd_orientation=landscape`;
                }else{
                    location.href= `https://ce.forclass.net/Memo/RoleChoice?type=ssj&session=${session}&dd_orientation=landscape`;
                }
            } catch (error) {
                console.log('发生错误：', error);
                // 报错了，重新跳转回登录页面
                this.$router.push(`/NxeDuYun/SignIn?state=${state}`);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;

    .loading {
        width: 100%;
        height: 100%;
    }
}
</style>