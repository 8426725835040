<template>
<!-- input框选中时下划线颜色要改变，这样css里面可以取到js里的变量IE不兼容css变量 -->
<div :style="{'--inputFocusColor':inputFocusBottomColor}">    
    <!-- 普通登录 -->
    <div v-if="isSecondLogin == false" class="general-login">
        <template v-if="!isSystemSignUp">
            <div class="account-container">
                <input 
                v-model="general.userName" 
                :class="{'input-focus':inputSelected=='用户名','name-input':true}"
                @click="inputSelected = '用户名'"
                type="text" 
                @blur="nameBlur"
                class="clear-input general-username" 
                spellcheck="false" 
                autoComplete="off"
                @focus="userNameFocus"
                ref="username" 
                placeholder="用户名">
                <div class="user-list" v-show="isShowHistory">
                    <div v-for="(item,index) in accountList" :key="index">
                        <div class="user-item" @mousedown.stop="clickHistory(item)">{{decrypt(item.loginName)}}</div>
                    </div>
                </div>
            </div>
            <input 
            v-model="general.password" 
            @click="inputSelected = '密码'"
            @blur="inputBlur"
            :class="{'input-focus':inputSelected=='密码'}" 
            type="password"
            class="clear-input general-pwd" 
            autoComplete="off"
            spellcheck="false" 
            placeholder="密码">
            <div class="verify-code-container"  @click="inputSelected = '图形验证码'" :class="{'input-focus':inputSelected=='图形验证码'}">
                <input 
                v-model="general.verifyCode" 
                type="text"
                @blur="inputBlur" 
                class="clear-input general-verify-code" 
                spellcheck="false" 
                placeholder="图形验证码">
                <div class="icon-container" @click="updateValidateCode">
                    <img :src="validateCode" class="code-img" alt="">
                    <img v-cdnimg src="/Account/images/signin/updateIcon.png" :class="{'update-code-icon':true, 'update-animation':clickUpdateIcon}" alt="">
                </div>
            </div>
            <div class="action-container">
                <div class="action-left">
                    <div class="action" @click="forgetPwd" v-show="isShowForgetPwd  && !isClient">忘记密码</div>
                    <div class="divider" v-show="isShowForgetPwd && isShowRegister && !isClient"></div>
                    <div class="action" @click="signUp" v-show="isShowRegister">{{ isClient ? '自行创建' : '立即注册' }}</div>
                    <div class="divider" v-show="isClient"></div>
                    <div class="action" @click="onSystemSignUp" v-show="isClient">系统创建</div>
                </div>
                <div class="action-right" @click="clickRememberPwd" v-show="isShowRememberPwd  && !isClient">
                    <div class="checkbox-pwd">
                        <!-- 原来的方案是在原生input框上覆盖一个自己画的选中框 -->
                        <!-- 现在的方案是改用svg嵌在html中 -->
                        <svg-icon iconName="checkbox-unchecked" v-show="isRememberPwd == false" :config="config" :previewConfig="previewConfig" :Mode="Mode"></svg-icon>
                        <svg-icon iconName="checkbox-checked" v-show="isRememberPwd == true" :config="config" :previewConfig="previewConfig" :Mode="Mode"></svg-icon>
                    </div>
                    <div class="remember-pwd">记住密码</div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="info-detail">
                <div class="tips-info">
                    <img v-cdnimg src="/Account/images/relation/icon_xtcj.png" alt="">
                    <span>系统创建</span>
                </div>
                <div class="row-1">系统已为您创建联想教研教学账号</div>
                <div class="row-2">用户名：<span>{{ systemUserName }}</span></div>
                <div class="row-2">初始密码：<span>{{ systemPassWord }}</span></div>
            </div>
        </template>
    </div>
    <!-- 二次登录 -->
    <div v-if="isSecondLogin == true" class="second-login">
        <div class="current-user">
            <div>你好，贺云清</div>
        </div>
        <div class="second-login-pwd">
            <input v-model="secondLoginPwd" type="password" class="clear-input second-pwd" spellcheck="false" placeholder="密码"
            :class="{'input-focus':inputSelected=='二次登录密码'}"
            @click="inputSelected = '二次登录密码'"
            @blur="inputBlur"
            >
        </div>
    </div>

    <!-- 登录按钮 -->
    <div class="login-btn" :style="{'background-color':`${loginBtnColor}`}" @click="clickLoginBtn" @keyup.enter="clickLoginBtn">
        <div>{{ isClient ? '关联并登录' : '登&nbsp;录' }}</div>
    </div>
    <div class="download-win" @click="$router.push('/Intelligence/installer')" v-if="showDownloadWin">下载客户端</div>
    <!-- 服务条款(只有联想显示) -->
    <div class="clause" v-if="showDownloadWin">
        <div @click="clickProtocol" class="clause-checkbox">
            <svg-icon iconName="checkbox-unchecked" v-show="isAgree == false" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
            <svg-icon iconName="checkbox-checked" v-show="isAgree == true" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
        </div>
        <div class="clause-text">
            <span class="clause-left-text" @click="clickProtocol">我已阅读并同意</span>
            <span class="clause-content" @click="openClauseDialog">	
                《用户许可协议》、
                《隐私政策》、
                《儿童个人信息保护规则》
            </span>
        </div>
    </div>
    <!-- 提示勾选同意协议 -->
    <el-dialog
    title="提示"
    :visible="isShowAgreeTip"
    width="30%"
    :before-close="closeAgreeTip">
    <span>请阅读并同意《用户许可协议》、《隐私政策》、《儿童个人信息保护规则》</span>
    <span slot="footer" class="dialog-footer">
        <el-button class="protocol-btn" type="primary" size="small" @click="closeAgreeTip">确 定</el-button>
    </span>
    </el-dialog>
    <el-dialog
    title="关于"
    :visible="isShowClause"
    :width="'960px'"
    :before-close="closeClauseDialog">
    <div class="clause-content" v-if="isShowClause">
        <div class="clause-tab-container">
            <div 
            :class="{'clause-tab':true,'clause-tab-active':clauseIndex == index}" 
            @click="selectClauseTab(index)"
            v-for="(item,index) in clauseList" :key="index">
                <div class="clause-tab-name">{{item.name}}</div>
                <div class="hight-light-line" v-show="clauseIndex == index"></div>
            </div>
        </div>
        <div class="clause-body">
            <user-license v-if="clauseIndex == 0"></user-license>
            <privacy v-if="clauseIndex == 1"></privacy>
            <child-info v-if="clauseIndex == 2"></child-info>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <div class="clause-footer" v-show="isShowAgreeBtn">
            <el-button class="protocol-btn" type="primary" size="small" @click="confirmClause">同意并继续</el-button>
        </div>
    </span>
    </el-dialog>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import SvgIcon from './SvgIcon.vue'
import {
    GET_ACCOUNT_ROLE,
    GET_LOGIN_SEED,
    UNIFIED_LOGIN,
    LENOVO_ACCOUNT_BINDING,
    LENOVO_ACCOUNT_REGISTER,
    VALIDATE_CAPTCHA
} from '@/http/api.js'
import childInfo from '@/views/About/ChildInfo.vue'
import userLicense from '@/views/About/UserLicense.vue'
import privacy from '@/views/About/Privacy.vue'
  export default {
    components: { SvgIcon ,childInfo,userLicense,privacy},
    name: "AccountLogin",
    props: ['isSecondLogin','config','previewConfig','Mode','bindEnterFlag', 'isClient', 'lenovoUserId', 'lenovoLoginName'],
    computed:{
        showDownloadWin(){
          let platform = this.$store.state.platform
          let platformNum = platform.PlatformSystemNumber              // 平台编号
          if(platformNum == '000040' || platformNum == '000041'){
            var device = this.getSessionVal("device")
            if(device && device == "lenovowin"){
              return false
            }
            if(window.winApi){
              return false
            }
            return true
          } else {
            return false
          }
        },
        isShowForgetPwd(){   // 是否显示忘记密码
            if(this.Mode == 'preview'){    // 预览模式
                if(this.previewConfig.basicConfig.forgetPasswordEnableStatus){
                    return true
                }else{
                    return false
                }
            }else{
                if(this.config.basicConfig.forgetPasswordEnableStatus){
                    return true
                }else{
                    return false
                }                       
            } 
        },
        isShowRegister(){   // 是否显示立即注册
            if(this.Mode == 'preview'){    // 预览模式
                if(this.previewConfig.basicConfig.registerNowEnableStatus){
                    return true
                }else{
                    return false
                }
            }else{
                if(this.config.basicConfig.registerNowEnableStatus){
                    return true
                }else{
                    return false
                }                       
            } 
        },
        isShowRememberPwd(){   // 是否显示记住密码
            if(this.Mode == 'preview'){    // 预览模式
                if(this.previewConfig.basicConfig.rememberPasswordEnableStatus){
                    return true
                }else{
                    return false
                }
            }else{
                if(this.config.basicConfig.rememberPasswordEnableStatus){
                    return true
                }else{
                    return false
                }                       
            } 
        },
        checkedColor(){
            if(this.isRememberPwd == true){
                return 'skyblue'
            }else{
                return 'white'
            }
        },
        inputFocusBottomColor() {   // 点击输入框时输入框底部的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.inputBoxSelect
            }else{                       
                return this.config.colorConfig.inputBoxSelect
            }
        },
        loginBtnColor(){  // 登录按钮颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.loginButton
            }else{
                return this.config.colorConfig.loginButton
            }
        },
        clickCheckbox(){  // 点击我已却独并同意服务条款
            this.isAgree = !this.isAgree
        }
    },
    watch:{
        bindEnterFlag:{
            handler:function(newVal, oldVal){
                let that = this
                if(newVal == 'AccountLogin'){
                    document.onkeydown = function(event){
                        if(event.key == 'Enter'){
                            that.clickLoginBtn() 
                        }
                    }
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            general: {  // 普通登录
                userName: '',
                password: '',
                verifyCode: '',   
            },
            secondLoginPwd: '',       // 二次登录的时候输入的密码
            clickUpdateIcon: false,   // 实现点击刷新图标旋转功能
            isRememberPwd: false,     // 是否记住密码
            inputSelected: '',       // 被选中的输入框名字，来添加不同的底部颜色
            validateCode: '',        // 数字验证码
            isAgree: false,           // 是否同意条款
            isShowAgreeTip: false,     // 是否显示需要同意协议的提示框
            isShowClause: false,       // 是否显示条款
            clauseList: [
                {name:'用户许可协议'},
                {name:'隐私政策'},
                {name:'儿童个人信息保护规划'},
            ],
            clauseIndex: 1,            // 当前激活的是哪个条款标签
            isShowAgreeBtn: false,     // 如何点展示按钮
            accountList: [],
            isShowHistory: false,    // 不展示历史
            historyFlag: false,      // 

            isSystemSignUp: false, // 是否系统创建
            systemUserName: '', // 默认用户名
            systemPassWord: '', // 默认密码
        }
    },
    methods: {
        userNameFocus(){
            console.log("获取焦点了");
            this.isShowHistory = true
            this.handleHistory()    // 处理历史账号回显
        },
        clickHistory(item){   // 点击了历史记录
            console.log("点击了历史记录",item);
            this.general.userName = this.decrypt(item.loginName)
            this.general.password = this.decrypt(item.pwd)
            this.isShowHistory = false
        },
        nameBlur(){    // 用户名失去焦点
            this.inputSelected = ''
            if(this.isShowHistory == true){
                this.isShowHistory = false
            }
        },
        inputBlur(){    //输入框失去焦点
            this.inputSelected = ''
        },
        async checkValidateCode(){   // 调新接口单独验证数字验证码
            let stamp = this.getSessionVal('stamp');
            if (stamp == "") stamp = uuidv4();
            let param = { code: this.general.verifyCode, id: stamp };
            let isValidateSucc = false;
            await VALIDATE_CAPTCHA(param).then(res => {
                let data = res.data;
                if(data && data.data) {
                    isValidateSucc = true;
                } else {
                    this.$message.error('验证码错误!');
                    this.updateValidateCode();
                }
            })
            return isValidateSucc;
        },
        async updateValidateCode(){  // 更新验证码
            this.general.verifyCode = ''    //刷新验证码时把原来的验证码置空
            let stamp = this.getSessionVal('stamp')
            if(stamp == '') {
                stamp = uuidv4();
            }
            this.updateSession('stamp', stamp);
            if(this.validateCode != '') {
                if(this.clickUpdateIcon == false) {
                    this.clickUpdateIcon = true;
                    setTimeout(async () => {
                        this.validateCode = await this.onGetCaptcha(stamp);
                        this.clickUpdateIcon = false;
                    }, 700);
                }
            } else {
                this.validateCode = await this.onGetCaptcha(stamp);
            }
        },
        forgetPwd(){   // 忘记密码
            this.$emit("forgetPwd")
        },
        handleHistory(){    // 处理显示历史内容
            let list = localStorage.getItem('history')
            if(list == null){
                this.isShowHistory = false    // 不显示历史
            }else{
                this.accountList = JSON.parse(list)
                this.isShowHistory = true
            }
        },
        signUp() {    //  注册账号
            if(this.showDownloadWin) {    // 如果是联想平台
                this.isShowClause = true
                this.clauseIndex = 1
                this.isShowAgreeBtn = true
            } else {
                this.$emit("signUp")
            }
        },
        onSystemSignUp() {
            let queryInfo = this.$route.query;
            LENOVO_ACCOUNT_REGISTER({ cmdInfo: queryInfo.token }).then(res => {
                if(res.data.ReturnCode == 1) {
                    if(this.isValidArray(res.data.result)){
                        let result = res.data.result;
                        this.systemUserName = result[0].LoginName;
                        this.systemPassWord = result[0].Password;
                        this.isSystemSignUp = true;
                        this.$emit('onSystemSignUp', this.isSystemSignUp);
                    }
                } else {
                    this.$message.error(res.data.ReturnText);
                }
            })
        },
        clickRememberPwd(){  // 点击记住密码
            this.isRememberPwd = !this.isRememberPwd
        },
        selectDiffRole(){   // 登录之后选择不同的角色
            // let param = {
            //     session:this.getSession(),
            //     hostName:location.hostname,
            //     page:0
            // }

            let param = this.getAccountRoleParam();
            console.log('accountLogin', param);
  
            GET_ACCOUNT_ROLE(param).then((res)=>{
                this.$emit('showChooseRole',res)   
            })
        },
        handleLogin(){     // 调用登录接口
            let count = 0;     // 计数器
            let timeOut = setInterval(()=>{
                if(count >= 20){
                    this.$message.error('网络超时，请检查您的网络');
                    this.updateValidateCode()   // 出错刷新验证码
                    clearInterval(timeOut)
                    return
                }
                count++
            },1000)
            let identity = this.getSessionVal('stamp');// 获取一个唯一的标志（比如时间戳）
            GET_LOGIN_SEED({ stamp: identity }).then((response) => {
                let data = response.data.result[0]
                let key = data.Key
                let iv = data.Iv
                let encryptedAccount = this.encrypt(this.isSystemSignUp ? this.systemUserName : this.general.userName, key, iv).replace(/\+/g, '%2B')  // 加密之后的账号
                let encryptedPwd = this.encrypt(this.isSystemSignUp ? this.systemPassWord : this.general.password, key, iv).replace(/\+/g, '%2B')     // 加密之后的密码
                 // 加密的信息
                let dataObj = {
                    UnifiedLoginMode: 4,    // 暂时写死是4
                    UniquelyIdentifies: encryptedAccount,
                    Certificate: encryptedPwd,
                    Other:{ stamp: identity }
                }
                UNIFIED_LOGIN(dataObj).then((LoginInfo) => {    // 加密账户名和密码调接口换取用户信息
                    count = 0     // 把计数器清0， 定时器关闭
                    clearInterval(timeOut)
                    let userInfo = LoginInfo.data    // 返回的数据
                    if(!this.isValidArray(userInfo.result)){  // 出错
                        let warningText = userInfo.ReturnText
                        let warningMessage = ''    // 警告信息
                        let errorNum = 0           // 错误次数
                        this.updateValidateCode()   // 出错刷新验证码
                        switch (warningText) {
                            case 'ErrCode:0 DB throwed Exception.':
                                warningMessage = "网络繁忙，请稍后再试";
                                break;
                            case 'ErrCode:0 Account is not exist.':
                                warningMessage = "此用户名不存在";
                                break;
                            case 'ErrCode:0 Password is not correct':
                                errorNum++
                                let msg = "用户名或密码不正确，您还可以尝试"+ (3-errorNum) +"次"
                                if(errorNum >= 3)
                                {
                                    msg = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试"
                                }
                                warningMessage = msg;
                                break;
                            case 'ErrCode:0 Account is forbidden':
                                warningMessage = "此账户已被禁用，请联系管理员";
                                break;
                            case 'ErrCode:0 Account expired':
                                warningMessage = "此账户过期，请联系管理员";
                                break;
                            case 'Invalid request':
                                warningMessage = "请勿频繁操作"
                                break;
                            case 'Too much failures':
                                warningMessage = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试";
                                break;
                            case 'Already binded':
                                warningMessage = "您所登录的账户已经被其他微信号绑定，请联系管理员确认账户绑定情况。";
                                break;
                            case 'WXLogin student not allowed':
                                warningMessage = "暂不支持使用学生账户绑定微信号。";
                                break;
                            case 'ErrCode:0 not allowed':
                                warningMessage = "抱歉，您没有权限！";
                                break;
                            case 'Teachers have no classes':
                                errmsg = "该账号没有任教班级，请先任教，再扫码绑定！";
                                break;
                            //case -104:
                            //    warningMessage = "会话无效，请联系管理员";
                            //    break;
                            default:
                                warningMessage = warningText;
                                break;
                        }
                        this.$message.error(warningMessage);
                        // return false
                    }else{    // 用户数据返回了
                        // 登录成功之后且勾选记住密码的时候，存到localStorage里面
                        if(this.isRememberPwd == true){  
                            let list = JSON.parse(localStorage.getItem('history'))
                            if(list != null){
                                let hasSaved = true
                                list.forEach((item)=>{
                                    if(item.loginName == this.encrypt(this.general.userName)){
                                        hasSaved = false
                                    }
                                })
                                if(hasSaved){
                                    list.unshift({
                                        loginName:this.encrypt(this.general.userName),
                                        pwd:this.encrypt(this.general.password)
                                    })
                                    localStorage.setItem('history',JSON.stringify(list))
                                }
                            }else{
                                let newList = []
                                newList.unshift({
                                    loginName:this.encrypt(this.general.userName),
                                    pwd:this.encrypt(this.general.password)
                                })
                                localStorage.setItem('history',JSON.stringify(newList))
                            }
                        }
                        // 如果有多个账号先选账号，再选角色
                        this.$emit('showChooseRole',userInfo.result)    // 把所有的账号列表传过去，显示选账号和选角色的页面 
                    }
                })
            })
        },
        clickLoginBtn() {  // 点击登录了
            if(navigator.onLine == true){
                if(this.Mode != 'preview'){      // 只有不是预览的时候，才走接口
                    if(this.isSystemSignUp && this.isClient) {
                        this.onLenovoAccountBind(() => {
                            this.handleLogin();
                        });
                    } else {
                        if(this.general.userName == ''){
                            this.$message.error('请填写用户名');
                            this.updateValidateCode()   // 出错刷新验证码
                            return false
                        }
                        if(this.general.password == ''){
                            this.$message.error('请填写密码');
                            this.updateValidateCode()   // 出错刷新验证码
                            return false
                        }
                        if(this.general.verifyCode == ''){
                            this.$message.error('请填写验证码');
                            this.updateValidateCode()   // 出错刷新验证码
                            return false
                        }
                        if(this.showDownloadWin){   // 联想平台
                            if(this.isAgree == false){   // 没有勾选
                                this.isShowAgreeTip = true
                                return false
                            }
                        } 
                        this.checkValidateCode().then(isValidateNumCodeSucc => {
                            if(isValidateNumCodeSucc){   // 如果图形验证码验证通过
                                if(!this.isClient) {
                                    this.handleLogin()   // 调用登录的接口获取信息，保存登录状态，处理登录成功之后的操作
                                } else {
                                    this.onLenovoAccountBind(() => {
                                        this.handleLogin();
                                    });
                                }
                            }
                        });
                    }
                }
            }else{
                this.$message.error({
                    message: '网络已断开连接',
                    iconClass: "null"
                });
            }
        },
        // 绑定联想账号
        onLenovoAccountBind(next) {
            let params = {
                lenovoUserId: this.lenovoUserId,
                lenovoLoginName: this.lenovoLoginName,
                loginName: this.isSystemSignUp ? this.systemUserName : this.general.userName,
                password: this.isSystemSignUp ? this.systemPassWord : this.general.password
            }
            LENOVO_ACCOUNT_BINDING(params).then(res => {
                if(res.data.ReturnCode == 1) {
                    next && next();
                } else {
                    this.$message.error(res.data.ReturnText);
                }
            })
        },
        clickProtocol(){  // 点击我已却独并同意服务条款
            this.isAgree = !this.isAgree
        },
        closeAgreeTip(){  // 关闭同意提示框
            this.isShowAgreeTip = false
        },
        selectClauseTab(index){  // 选择条款导航
            this.clauseIndex = index
        },
        closeClauseDialog(){  // 关闭条款弹窗
            this.isShowClause = false
        },
        confirmClause(){   // 同意条款
            this.isShowClause = false
            this.$emit("signUp")
        },
        openClauseDialog(){  // 打开条款预览页面
            this.isShowClause = true
            this.isShowAgreeBtn = false
            this.clauseIndex = 1
        }
    },
    created(){
    },
    mounted(){
        this.$bus.$on('clientSwitchToLogin', (val) => {
            let obj = JSON.parse(val);
            this.general.userName = obj.userName;
            this.general.password = obj.passWord;
        })
        this.updateValidateCode()    // 页面加载的时候初始化数字验证码
    }
  }
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox();  // 清除原生input样式
.input-focus{
    border-bottom-color: var(--inputFocusColor) !important;
}
.general-login {   // 普通登录
    display: flex;
    flex-direction: column;
    height: 250px;
    .account-container{
        position: relative;
        .name-input{
            width: 100%;
        }
        .user-list {
            position: absolute;
            width: 100%;
            background-color: #ffffff;
            border-radius: 4px;
            border: 1px solid #cecece;
            top:62px;
            max-height: 160px;
            overflow-y: auto;
            .user-item{
                height: 30px;
                padding-left: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .user-item:hover{
                background-color: #f2f2f2;
            }
        }
    }
    .general-username {  // 用户名
        box-sizing: border-box;
        height: 38px;
        border: 0px;
        border-bottom: 2px solid #F2F3F5;
        margin-top: 24px;
        margin-bottom: 32px;
    }
    .general-pwd {  // 密码
        box-sizing: border-box;
        height: 38px;
        border: 0px;
        border-bottom: 2px solid #F2F3F5;
        margin-bottom: 32px;
    }
    .verify-code-container {
        border-bottom: 2px solid #F2F3F5;
        margin-bottom: 24px; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        .code-img{
            width: 100px;
            height: 40px;
        }
        .general-verify-code{  // 验证码
            box-sizing: border-box;
            height: 38px;
            border: 0px;
            flex: 1;
        }
        .icon-container{
            box-sizing: border-box;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .update-code-icon{
                width: 18px;
                height: 18px;
                margin-right: 16px;
                margin-top: 5px;
            }
            .update-animation{   // 点击刷新二维码的图标时旋转
                animation: loading 0.7s ease-in-out infinite forwards;
            }
            @keyframes loading {  // 旋转动画
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(-360deg);
                }
            }
        }
    }
    .action-container{
        display: flex;
        justify-content: space-between;
        .action-left{
            display: flex;
            align-items: center;
            margin-left: 8px;
            .action{
                margin-right: 11px;
                color: #4E5969;
                font-size: 13px;
                line-height: 20px;
                cursor: pointer;
            }
            .divider{
                margin-right: 11px;
                width: 1px;
                height: 14px;
                background: #E6E6EB;
            }
        }
        .action-right{
            display: flex;
            align-items: center;
            cursor: pointer;
            .remember-pwd{
                color: #4E5969;
                font-size: 13px;
                line-height: 19px;
                margin-left: 7px;
                cursor: pointer;
            }

            label {
                position: relative;
                cursor: pointer;
                input {
                    cursor: pointer;
                }
                input:checked + .show-box {
                    background: #ff6a00;
                    border: 1px solid white;
                    border-radius: 2px;
                }
                .show-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    border-radius: 2px;
                    border: 1px solid #d8d8d8;
                    background: white;          // 这里取个巧，与下面颜色一样而已
                    &:before { // 使用了 absolute 所以无所谓是 before 还是 after
                        content: ''; // 空白内容占位，当做盒模型处理，见下面
                        position: absolute;
                        top: 2px;
                        left: 5.5px;
                        width: 3px; // 勾的短边
                        height: 8px; // 勾的长边
                        border: solid white; // 勾的颜色
                        border-width: 0 2px 2px 0; // 勾的宽度
                        transform: rotate(45deg); // 定制宽高加上旋转可以伪装内部的白色勾
                    }
                }
            }
        }
    }
    .info-detail {
        font-size: 16px;
        .tips-info {
            display: flex;
            align-items: center;
            span {
                margin-left: 5px;
            }
        }
        .row-1 {
            margin-top: 40px;
            color: #131314;
        }
        .row-2 {
            color: #606266;
            margin: 15px 0;
            span {
                color: #F15F00;
            }
        }
    }
}
.second-login{   // 二次登录页面的样式
    .current-user {
        margin-top: 24px;
        margin-bottom: 32px;
        font-size: 16px;
        font-weight: 600;
    }
    .second-login-pwd{
        .second-pwd {  // 密码
            box-sizing: border-box;
            height: 38px;
            width: 100%;
            border: 0px;
            border-bottom: 2px solid #F2F3F5;
        }
    }
}
.login-btn{  // 登录
    margin-top: 32px;
    width: 392px;
    height: 40px;
    background-color: #ff6a00;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}

.download-win{
    color: #131414;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 1px solid #C1CBDB;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.clause{
    display: flex;
    align-items: flex-start;
    width: 392px;
    .clause-checkbox{
        cursor: pointer;
    }
    .clause-text{
        color: #4E5969;
        font-size: 13px;
        margin-left: 7px;
        .clause-left-text{
            cursor: pointer;
        }
        .clause-content {
            color: #4E82E9;
            cursor: pointer;
        }
    }

    label {
        position: relative;
        cursor: pointer;
        input {
            cursor: pointer;
        }
        input:checked + .show-box {
            background: #ff6a00;
            border: 1px solid white;
            border-radius: 2px;
        }
        .show-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 2px;
            border: 1px solid #d8d8d8;
            background: white; // 这里取个巧，与下面颜色一样而已
            &:before { // 使用了 absolute 所以无所谓是 before 还是 after
                content: ''; // 空白内容占位，当做盒模型处理，见下面
                position: absolute;
                top: 1px;
                left: 5.5px;
                width: 3px; // 勾的短边
                height: 8px; // 勾的长边
                border: solid white; // 勾的颜色
                border-width: 0 2px 2px 0; // 勾的宽度
                transform: rotate(45deg); // 定制宽高加上旋转可以伪装内部的白色勾
            }
        }
    }
}
.protocol-btn{
    background: #FF6A00;
    border-color: #FF6A00;
}
.clause-tab-container {
    display: flex;
    align-items: center;
    .clause-tab {
        font-size: 16px;
        margin-right: 24px;
        color: #909399;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }
    .clause-tab-active{
        color: #131414;
    }
    .hight-light-line {
        height: 2px;
        border-radius: 1px;
        width: 100%;
        background: #FF6A00;
    }
}
.clause-content{
    .clause-body {
        margin-top: 16px;
        height: 530px;
        overflow: hidden;
        overflow-y: auto;
    }
}
.dialog-footer{
    .clause-footer {
        display: flex;
        justify-content: center;
    }
}

// H5移动端
@media screen and (max-width: 768px) {
    .action-left {
        div {
            display: none;
        }

        .action:nth-of-type(3) {
            display: block;
        }
    }
    .action-right {
        display: none !important;
    }
    .login-btn{
        width: 100%;
    }
    .verify-code-container{
        .general-verify-code{
            width: calc(100% - 100px);
        }
        .icon-container{
            width: 100px;
            overflow: hidden;
        }
    }
    .clause{
        width: 100%;
    }
}

</style>